import { useState, useEffect, useRef } from 'react';
import { getCookie } from './cookies';
import { print } from 'graphql';
import { COOKIES } from '@constants/cookies';
import { injectScript } from '../utils/scripts';

// Hook
export const useScript = (src: any) => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');
  useEffect(() => {
    // Allow falsy src value if waiting on other data needed for
    // constructing the script URL passed to this hook.
    if (!src) {
      setStatus('idle');
      return;
    }
    // Fetch existing script element by src
    // It may have been added by another intance of this hook
    let script = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = injectScript(src);
      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = event => {
        script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus(script.getAttribute('data-status'));
    }
    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = event => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };
    // Add event listeners
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);
    // Remove event listeners on cleanup
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src]);
  return status;
};

export const useClickInsideElement = () => {
  const [isClickInside, setIsClickInside] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsClickInside(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isClickInside, setIsClickInside };
};

export const useFetch = (query: any) =>
  fetch(`${process.env.GATSBY_APP_SYNC_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': `${process.env.GATSBY_APP_SYNC_KEY}`,
      authorization: getCookie(COOKIES.token) || '',
      'x-store-code': getCookie(COOKIES.currency) || 'GBP',
    },
    body: JSON.stringify({
      query: print(query),
    }),
  }).then(res => res.json());
