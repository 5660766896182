import React, { useContext, useState } from 'react';
import { navigate } from '@reach/router';
// context
import { GlobalContext } from '@store/global-state';
//interfaces
import { IBannerData } from '@interfaces/global';
// utils
import { isExternalUrl, isAbsoluteUrl } from '../../utils/url';
// styles
import styles from './sitewide-banner.module.scss';

interface IProps {}

const SitewideBanner: React.FC<IProps> = ({}) => {
  const [isCopied, setIsCopied] = useState(false);

  const { setBannerData, setBannerHeight, bannerData, windowIsScrolled, showStickyNav } = useContext(GlobalContext);

  const {
    label,
    buttonLabel,
    buttonUrl,
    backgroundColor,
    textColor,
    isDismissible,
    autoHide,
  } = bannerData as IBannerData;

  const handleClose = () => {
    if (bannerData !== null) {
      setBannerData(bannerData);
      setBannerHeight(0);
    }
  };

  const handleClick = () => {
    handleClose();
    if (isExternalUrl(buttonUrl)) {
      window.open(buttonUrl, '_blank');
      return;
    }
    if (isAbsoluteUrl(buttonUrl)) {
      navigate(buttonUrl);
      return;
    }

    const hasQueryString = buttonUrl.includes('?');

    if (hasQueryString) {
      navigate(buttonUrl);
      window.location.reload();
      return;
    }

    const internalUrl = `${process.env.GATSBY_HOST_NAME}${buttonUrl}`;
    navigate(internalUrl);
  };

  const isAutoHideActive = () => {
    return autoHide
      ? `${windowIsScrolled ? 'wrapper-scrolled' : ''} ${showStickyNav ? 'show-sticky' : ''} ${
          styles.wrapper
        } easing-style`
      : `${styles.wrapper} easing-style`;
  };

  const addSpanTagsAroundDiscountCode = () => {
    if (label && label.includes('#')) {
      let count = 0;
      const newLabel = label.split('').map((char: string) => {
        if (char === '#') {
          count++;
          if (count === 1) {
            return '<span class="discount-code" data-copy-label="click to copy">';
          } else if (count === 2) {
            return '</span>';
          }
        }
        return char;
      });
      return newLabel.join('');
    }
    return label;
  };

  const clickToCopy = () => {
    var findValueBetweenHashes = /\#([^#]+)\#/;
    var matches = findValueBetweenHashes.exec(label);
    matches && navigator.clipboard.writeText(matches[1]);
    setIsCopied(true);
    document.getElementsByClassName('discount-code')[0].setAttribute('data-copy-label', 'copied!');

    setTimeout(() => {
      document.getElementsByClassName('discount-code')[0].setAttribute('data-copy-label', 'click to copy');
    }, 1000);
  };

  const applyBannerBackgroundStyles = () => {
    if (bannerData && bannerData.hasOwnProperty('backgroundColor')) {
      return backgroundColor ? backgroundColor : '#e34b47';
    } else {
      return 'transparent';
    }
  };

  return (
    <div
      id="siteWideBanner"
      className={`${isAutoHideActive()}`}
      ref={el => el && setBannerHeight(label !== undefined ? el.getBoundingClientRect().height : 0)}
      style={{
        backgroundColor: applyBannerBackgroundStyles(),
      }}
    >
      {
        <>
          <div className={styles.container}>
            <div className={styles.row}>
              <p
                onClick={clickToCopy}
                dangerouslySetInnerHTML={{
                  __html: addSpanTagsAroundDiscountCode(),
                }}
                style={{
                  color: textColor ? textColor : '#fff',
                }}
                className={isCopied ? 'copied' : ''}
              ></p>
              {buttonLabel && (
                <>
                  <button type="button" className={styles.button} onClick={handleClick}>
                    {buttonLabel}
                  </button>
                  <span className={styles.linkButton} onClick={handleClick}>
                    {buttonLabel}
                  </span>
                </>
              )}
            </div>
          </div>
          {isDismissible && (
            <button type="button" className={styles.buttonClose} onClick={handleClose}>
              <img src={'/images/icon_close.svg'} alt="Close" />
            </button>
          )}
        </>
      }
    </div>
  );
};

export default SitewideBanner;
