import { GlobalContext } from '@store/global-state';
import { logout } from '@services/auth';
import { navigate } from 'gatsby';
import { useContext } from 'react';
import { updateCachedBasket } from './useBasket';
import { useCreateGuest } from './useCreateGuest';

const useLogoutUser = () => {
  const { startGuestCreate } = useCreateGuest();
  const { setIsLoggedIn } = useContext(GlobalContext);

  const logoutUser = () => {
    
    logout();
    setIsLoggedIn(false);
    updateCachedBasket(null);
    navigate('/');
    startGuestCreate();
  };

  return logoutUser;
};

export default useLogoutUser;