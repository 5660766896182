import { Link } from 'gatsby';
import React, { useContext } from 'react';

import { GlobalContext } from '@store/global-state';
import { MENU_DATA } from './data';
import { SIDEBAR_VIEWS } from '@components/sidebar/constants';

import styles from './mobile-menu-links.module.scss';
import { Badge } from 'vitl-component-library';

interface Item {
  label: string;
  link: string;
  tag: string;
}

type Items = Item[];

const MobileMenuLinks = () => {
  const { setSidebar } = useContext(GlobalContext);

  const closeSidebar = () => setSidebar(SIDEBAR_VIEWS.empty);

  const renderItem = (items: Items) => {
    return items.map(item => (
      <div onClick={closeSidebar}>
        {item.link.startsWith('/') ? (
          <Link key={item.label} className={styles.link} to={item.link}>
            {item.label}
            {item.tag && <Badge label={item.tag} bgColor={'#e8544c'} labelColor={'#ffffff'} isSmall={true} />}
          </Link>
        ) : (
          <a key={item.label} className={styles.link} href={item.link}>
            {item.label}
          </a>
        )}
      </div>
    ));
  };
  return (
    <div className={styles.menuLinks}>
      {MENU_DATA.map(item => (
        <div key={item.label} className={styles.oneSection}>
          <p>{item.label}</p>
          {renderItem(item.items)}
        </div>
      ))}
    </div>
  );
};

export default MobileMenuLinks;
