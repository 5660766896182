import React, { FC, useEffect, useState } from 'react';
import { SwiperComponent } from 'vitl-component-library';
import Swiper from 'swiper';
//interfaces
import { IBasketCrossSellProduct } from '@src/types/product';
//components
import ProductCarouselItem from './ProductCarouselItem';

interface IProductsCarouselProps {
  products: IBasketCrossSellProduct[] | undefined;
  onClickProduct?: (sku: string) => void;
  setActiveSlide: (slide: number) => void;
}

const ProductsCarousel: FC<IProductsCarouselProps> = ({
  products,
  onClickProduct,
  setActiveSlide,
}) => {
  const [swiperInstance, setSwiperInstance] = useState<Swiper>();

  useEffect(() => {
    swiperInstance?.updateSlides();
  }, [products]);

  return (
    <SwiperComponent
      onSwiper={swiper => setSwiperInstance(swiper)}
      observer
      onRealIndexChange={swiper => setActiveSlide(swiper.realIndex + 1)}
      overlaps
      showArrows
      avoidOnDesktopIfLessThan3Elements={false}
      breakpoints={{
        // when window width is >= 320px
        320: {
          slidesPerView: 1.2,
        },
        640: {
          slidesPerView: 1.2,
        },
        953: {
          slidesPerView: 1.2,
        },
        1200: {
          slidesPerView: 1.2,
        },
      }}
    >
      {products?.map((product, i) => {
        return (
          <ProductCarouselItem
            key={`${product.sku}${i}`}
            product={product}
            onClickProduct={onClickProduct}
          />
        );
      })}
    </SwiperComponent>
  );
};

export default ProductsCarousel;
