import { COOKIE_DATA, PRODUCT_COOKIES } from '@constants/variables';
import { COOKIES, COOKIE_EXPIRES_IN } from '@src/constants/cookies';
import { getCookie, removeCookie, setCookie } from '@src/services/cookies';
import {
  essential20OffSetCookie,
  essentialAffiliateSetCookie,
  essentialFreeTrialSetCookie,
  essentialReferalSetCookie,
  pPSeventyPercentOffSetCookie,
  pPfiftyPercentOffSetCookie,
  pPfiftyFivePercentOffSetCookie,
  pPfreeTrialSetCookie,
  pPthirtyPercentOffSetCookie,
} from '@src/utils/coupons';

export type offerProduct = '4pp' | 'essential' | 'coupon';
export type offerTypes = 'freeTrial' | '50off' | '30off' | '20off' | '55off' | '70off' | 'none';
export type offerReferrer = 'tiktok' | 'fb' | 'GA' | 'affiliate' | 'referal' | 'none';

const couponCodeService = () => {
  const setOffer = (
    product: offerProduct,
    type: offerTypes = 'none',
    referrer: offerReferrer = 'none'
  ) => {
    if (product === '4pp') {
      if (type === 'freeTrial') {
        if (referrer === 'tiktok') {
          pPfreeTrialSetCookie(PRODUCT_COOKIES.PERSONALISED_PLUS.value.TIKTOK);
          return;
        }
        if (referrer === 'fb') {
          pPfreeTrialSetCookie(PRODUCT_COOKIES.PERSONALISED_PLUS.value.FB);
          return;
        }
        if (referrer === 'GA') {
          pPfreeTrialSetCookie(PRODUCT_COOKIES.PERSONALISED_PLUS.value.GA);
          return;
        }
        pPfreeTrialSetCookie();
      }
      if (type === '70off') {
        pPSeventyPercentOffSetCookie();
      }
      if (type === '55off') {
        pPfiftyFivePercentOffSetCookie();
      }
      if (type === '50off') {
        pPfiftyPercentOffSetCookie();
      }
      if (type === '30off') {
        pPthirtyPercentOffSetCookie();
      }
    }
    if (product === 'essential') {
      if (type === 'freeTrial') {
        //? Not used
        essentialFreeTrialSetCookie();
      }
      if (type === '20off') {
        essential20OffSetCookie();
      }
      if (referrer === 'affiliate') {
        essentialAffiliateSetCookie();
      }
      if (referrer === 'referal') {
        //? Not used
        essentialReferalSetCookie();
      }
    }
  };

  const setCoupon = (coupon: string) => {
    setCookie(COOKIES.couponCode, coupon, COOKIE_EXPIRES_IN.oneDay);
  };

  const getPPOfferTypeFromValue = (val?: string | null) => {
    if (val === PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_PERCENT_OFF) return '50off';
    if (val === PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_FIVE_PERCENT_OFF) return '55off';
    if (val === PRODUCT_COOKIES.PERSONALISED_PLUS.value.THIRTY_OFF_TREE_MONTH) return '30off';
    if (val === PRODUCT_COOKIES.PERSONALISED_PLUS.value.FREE_TRIAL) return 'freeTrial';
    if (val === PRODUCT_COOKIES.PERSONALISED_PLUS.value.SEVENTY_OFF) return '70off';
  };

  const getPPCouponCodeFromValue = (val?: string) => {
    if (val === '50off') return COOKIE_DATA['50-off-personalised-one-month'].couponCode;
    if (val === '55off') return COOKIE_DATA['55-off-personalised-one-month'].couponCode;
    if (val === '30off') return COOKIE_DATA['30-off-3'].couponCode;
    if (val === '70off') return COOKIE_DATA['70-off-personalised-one-month'].couponCode;
  };

  const getOffer = (product: offerProduct) => {
    if (product === '4pp') {
      return getCookie(COOKIES.personalisedPlusPartnership);
    }
    if (product === 'essential') {
      return getCookie(COOKIES.essentialOnePartnership);
    }
    if (product === 'coupon') {
      return getCookie(COOKIES.couponCode);
    }
    return null;
  };

  const getDiscountNumber = (offer?: string | null) => {
    if (!offer) return 0;
    if (offer === '50-off-personalised-plus') return 50;
    if (offer === '55-off-personalised-plus') return 55;
    if (offer === '30-off-3months') return 30;
    if (offer === '70-off-personalised') return 70;
    return 100;
  };

  const removeOffer = (offer: 'coupon' | 'all' = 'coupon') => {
    if (offer === 'coupon') {
      removeCookie(COOKIES.couponCode);
      return;
    }
    removeCookie(COOKIES.couponCode);
    removeCookie(COOKIES.personalisedPlusPartnership);
  };

  return {
    setOffer,
    getOffer,
    removeOffer,
    setCoupon,
    getPPOfferTypeFromValue,
    getPPCouponCodeFromValue,
    getDiscountNumber,
  };
};

export default couponCodeService;
