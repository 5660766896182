import React from 'react';
import styles from './custom-input.module.scss';
import Loader from '@src/components/loader';

interface IProps {
  id: string;
  type?: string;
  label: string;
  register: any;
  error?: any;
  errorMessage?: string;
  isDirty: boolean;
  disabled?: boolean;
  autocomplete?: string;
  onInput?: (value: any) => void;
  customClass?: string;
  loading?: boolean;
}

const CustomInput: React.FC<IProps> = ({
  id,
  type = 'text',
  label,
  register,
  error,
  errorMessage,
  isDirty,
  disabled = false,
  autocomplete = 'on',
  onInput,
  customClass = '',
  loading = false,
}) => {
  return (
    <div>
      <div
        className={
          !error
            ? customClass
              ? styles[customClass + 'Container']
              : styles.container
            : customClass
            ? styles[customClass + 'ErrorContainer']
            : styles.containerError
        }
      >
        <input
          id={id}
          name={id}
          type={type}
          ref={register}
          disabled={disabled}
          autoComplete={autocomplete}
          onInput={onInput ? e => onInput((e.target as HTMLInputElement).value) : undefined}
        />
        <label htmlFor={id} className={!isDirty ? styles.placeholder : styles.placeholderActive}>
          {label}
        </label>
        {loading ? (
          <div className={styles.inlineLoader}>
            <div className={styles.loader} />
          </div>
        ) : null}
      </div>
      {error && (
        <small className={customClass ? styles[customClass + 'Error'] : styles.error}>
          {errorMessage}
        </small>
      )}
    </div>
  );
};

export default CustomInput;
