import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
// components
import SitewideBanner from '../sitewide-banner';
import UtmModal from '../utm-modal';
import Footer from '../footer';
import ModalAddCoupon from '../modal-add-coupon';
import ModalChangeCurrency from '../modal-change-currency';
import ModalSpecialRequirementCoupon from '../modal-special-requirement-coupon';
import ModalCrossSellSingle from '../modal-cross-sell-single';
import Basket from '../basket/basket';
import Intercom from '../intercom';
import Search from '../search';
import Loader from '@components/loader';
import ModalMessage from '../modal-message';
import TopNavigationComponent from '@components/top-nav/top-navigation';
import Sidebar from '@components/sidebar';
import { SIDEBAR_VIEWS } from '@components/sidebar/constants';
import MobileSidebarMenu from '@components/mobile-sidebar-menu';
import MobileFiltersSidebar from '@features/shop/components/mobile-filters/mobile-filters-sidebar';
import PlanModal from '@features/shop/components/plan-modal/plan-modal';
//hooks
import { useLayout } from '@hooks/useLayout';
// styles
import 'lazysizes';
import '../../styles/normalize.scss';
import '../../styles/global.scss';
import fadeTransition from '../../styles/fade.module.scss';
import styles from './layout.module.scss';
import { useIsPageLoaded } from '@src/hooks/usePageLoaded';
import { useGetUserDetails } from '@src/hooks/useGetUserDetails';

interface IProps {
  showHeader?: boolean;
  showFooter?: boolean;
  children: React.ReactNode;
}

const LayoutContent: React.FC<IProps> = ({ showFooter, showHeader, children }) => {
  const {
    loading,
    loadingBannerContent,
    bannerData,
    site,
    directusSetting,
    bannerHeight,
    allDirectusGenericPage,
    showCrossSellSingle,
    messageModal,
    utmModalData,
    showBasket,
    showSearch,
    setShowSearch,
    sidebar,
    showShopModal,
  } = useLayout();

  useGetUserDetails();

  if (loading) return <Loader />;

  return (
    <div>
      {!loadingBannerContent && bannerData !== null && <SitewideBanner />}
      {showHeader && (
        <TopNavigationComponent siteTitle={site.siteMetadata.title} logo={directusSetting.logo} />
      )}
      <Sidebar>
        {SIDEBAR_VIEWS.navigation === sidebar && <MobileSidebarMenu />}
        {SIDEBAR_VIEWS.filters === sidebar && <MobileFiltersSidebar />}
      </Sidebar>
      <main
        className={showHeader ? styles.main : styles.mainNoPadding}
        style={{ marginTop: bannerHeight }}
      >
        {children}
      </main>
      {showFooter && <Footer content={directusSetting} navItems={allDirectusGenericPage.edges} />}
      {showBasket && <Basket />}
      <div id="modalContainer" />

      <ModalAddCoupon />
      <ModalSpecialRequirementCoupon />
      <ModalChangeCurrency />

      {showCrossSellSingle && <ModalCrossSellSingle />}
      {messageModal.visible && <ModalMessage />}

      {utmModalData && (
        <UtmModal
          type={utmModalData.type}
          image={utmModalData.image.filename_disk}
          redirectPath={utmModalData.redirect_path}
        />
      )}

      <Intercom />

      <CSSTransition
        in={showShopModal}
        timeout={300}
        classNames={{
          ...fadeTransition,
        }}
        unmountOnExit
      >
        <PlanModal />
      </CSSTransition>

      <CSSTransition in={showSearch} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
        <Search isOpen={showSearch} toggleSearch={setShowSearch}></Search>
      </CSSTransition>
    </div>
  );
};

LayoutContent.defaultProps = {
  showHeader: true,
  showFooter: true,
};

export default LayoutContent;
