import { useCallback } from 'react';
// services
import { getCookie, setCookie } from '../services/cookies';
// queries
import { useMutation } from 'react-apollo';
import { CREATE_GUEST } from '../queries/guest';
// constants
import { COOKIES } from '../constants/cookies';

const useCreateGuest = () => {
  const [createGuest, { loading, error }] = useMutation(CREATE_GUEST, {
    onCompleted: res => res?.guest_create && setCookie(COOKIES.guestToken, res.guest_create, 5 * 60 * 60),
  });

  const startGuestCreate = useCallback(() => {
    const isGuestCreated = getCookie(COOKIES.guestToken);

    if (!isGuestCreated) {
      createGuest();
    }
  }, [createGuest]);

  return {
    startGuestCreate,
    loading,
    error,
  };
};

export { useCreateGuest };
