import React from 'react';
import { Icon } from 'vitl-component-library';

import styles from './paymentCardsIcons.module.scss';

interface IProps {
  style?: React.CSSProperties;
}

const PaymentCardsIcons = ({ style }: IProps) => (
  <div className={styles.cards} style={style}>
    <Icon icon="visa" size={40} />
    <Icon icon="american-express" size={40} />
    <Icon icon="mastercard" size={40} />
  </div>
);

export default PaymentCardsIcons;
