import React, { useEffect, useRef, useState } from 'react';
import useClickedOutside from '@hooks/useClickedOutside';
import styles from './select.module.scss';
import { v4 as uuidv4 } from 'uuid';

export interface IOption {
  value: number;
  label: string;
}

interface IProps {
  options: IOption[];
  onSelect: (option: IOption) => void;
  loading?: boolean;
  defaultValue?: IOption;
}

const Select: React.FC<IProps> = ({ options, onSelect, defaultValue }: IProps) => {
  const handleSelectOption = (event: any) => {
    const option: IOption = {
      value: +event.target.value,
      label: event.target.selectedOptions[0].label,
    };
    onSelect && onSelect(option);
  };

  return (
    <div className={styles.select}>
      <img className={styles.chevron} src={'/images/icon_chevron.svg'} alt="icon chevron" />
      <select className={styles.selectElem} onChange={event => handleSelectOption(event)}>
        {options.map(option => (
          <option
            selected={option.value === defaultValue?.value}
            key={option.value}
            value={option.value}
            label={option.label}
            className={styles.option}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
