import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
// context
import { GlobalContext } from '@store/global-state';
import { setCookie, removeCookie } from '../../services/cookies';
// components
import Modal from '../modal';
import ModalHeader from '../modal/modal-header';
import ModalBody from '../modal/modal-body';
// services
import { CURRENCY_MAP } from '../../constants';
// styles
import styles from './modal-change-currency.module.scss';
import fadeTransition from '../../styles/fade.module.scss';
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';

const ModalChangeCurrency: React.FC = () => {
  const { showChangeCurrency, setShowChangeCurrency, currencyCode, setCurrencyCode } = useContext(
    GlobalContext
  );

  const setCurrency = (currency: string) => {
    removeCookie(COOKIES.currency);
    setCookie(COOKIES.currency, currency, COOKIE_EXPIRES_IN.thirtyDays);
    setCurrencyCode(currency);
    setShowChangeCurrency(false);
    window.location.reload();
  };

  const getClassName = (currency: string) =>
    currency === currencyCode ? styles.chosenCurrency : styles.currency;

  return (
    <CSSTransition
      in={showChangeCurrency}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal handleClose={() => setShowChangeCurrency(false)}>
        <ModalHeader>
          <h3>Select your currency</h3>
          <hr />
        </ModalHeader>
        <ModalBody>
          <div className={styles.wrapper}>
            <ul className={styles.currencyList}>
              {CURRENCY_MAP.map(({ currencyCode, currencySymbol }) => (
                <li
                  data-testid={`choose-currency-${currencyCode.toLowerCase()}`}
                  onClick={() => setCurrency(currencyCode)}
                  className={getClassName(currencyCode)}
                  key={currencyCode}
                >
                  <div>{currencyCode}</div>
                  <div className={styles.currencySymbol}>{currencySymbol}</div>
                </li>
              ))}
            </ul>
          </div>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default ModalChangeCurrency;
