import React from 'react';
import { CSSTransition } from 'react-transition-group';
import fadeTransition from '@src/styles/fade.module.scss';
import Modal from '@components/modal';
import ModalBody from '@components/modal/modal-body';
import styles from '@components/basket/basket.module.scss';

interface IProps {
  isEstimatedDeliveryModalVisible: boolean;
  setIsEstimatedDeliveryModalVisible: (value: boolean) => void;
}
const EstimateDeliveryModal: React.FC<IProps> = ({
  isEstimatedDeliveryModalVisible,
  setIsEstimatedDeliveryModalVisible,
}) => {
  return (
    <CSSTransition
      in={isEstimatedDeliveryModalVisible}
      timeout={300}
      classNames={{ ...fadeTransition }}
      unmountOnExit
    >
      <Modal handleClose={() => setIsEstimatedDeliveryModalVisible(false)}>
        <ModalBody>
          <h2>Estimated Delivery</h2>
          <p className={styles.popupDescription}>
            Your shipping cost will be recalculated after you enter a delivery address
          </p>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default EstimateDeliveryModal;
