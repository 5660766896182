import React, { useContext, useEffect } from 'react';

import { GlobalContext } from '@store/global-state';
import ChooseYourPlan from './choose-your-plan';

import styles from './plan-modal.module.scss';

const PlanModal = () => {
  const { setShowShopModal, setCurrentProductAndPlanData, bannerData } = useContext(GlobalContext);

  const handlePlanModalClose = () => {
    setShowShopModal(false);
    setCurrentProductAndPlanData(null);
  };

  useEffect(() => {
    document.body.classList.add('body-fixed');
    return () => {
      document.body.classList.remove('body-fixed');
    };
  }, []);

  return (
    <div className={styles.planModalWrapper}>
      <div className={`${styles.planModal} ${bannerData ? styles.withBanner : ''}`}>
        <div className={styles.content}>
          <ChooseYourPlan />
        </div>
        <img className={styles.close} src={'/images/icon_close.svg'} alt="Close" onClick={handlePlanModalClose} />
      </div>
    </div>
  );
};

export default PlanModal;
