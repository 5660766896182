import React, { Fragment, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
//components
import SubmitButton from '@components/submit-button';
import SubscriptionBenefits from './subscription-benefits';
//services
import { formatPrice } from '@services/format';
import { getCookie } from '@services/cookies';
//constants
import { COOKIES } from '@constants/cookies';
import { SUBSCRIPTION_PLANS } from '@constants/subscription-plans';
import { OUT_OF_STOCK } from '@src/constants/messages';
//hooks
import { useAddToBasket } from '@features/shop/hooks/useAddToBasket';
import { useShopText } from '@features/shop/hooks/useShopText';
import { useQueryParamBoolean } from '@hooks/useQueryParams';
//context
import { GlobalContext } from '@store/global-state';
//types
import { IProductPlan } from '../../../../types/product';
import { IBasket } from '../../../../types/basket';
//styles
import styles from './add-to-basket-new.module.scss';

interface IProps {
  onComplete: (basketData: IBasket) => void;
  productPlans?: IProductPlan[];
  variant?: 'shop' | 'product';
  isAvailable?: boolean;
}

const AddToBasketNew = ({
  onComplete,
  productPlans,
  variant = 'shop',
  isAvailable = true,
}: IProps) => {
  if (!isAvailable) {
    return <h4>{OUT_OF_STOCK}</h4>;
  }

  const hasRouteOneOff = useQueryParamBoolean('oneOff');

  const shopText = useShopText();
  const [isSubscriptionSelected, setIsSubscriptionSelected] = useState(true);
  const {
    setShowBasket,
    setCurrentProductAndPlanData,
    setHasUserAddedProductToBasket,
  } = useContext(GlobalContext);

  const isSubscription = (plan: IProductPlan) => !!plan?.deliveryFrequency;

  const defaultPlan = hasRouteOneOff
    ? productPlans?.find(plan => plan.sku.includes('one-time'))
    : productPlans?.find(plan => plan.default);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      sku: defaultPlan?.sku || (productPlans && productPlans[0]?.sku),
    },
  });

  const onCompletedCallback = (basketData: IBasket) => {
    const lastModifiedItem = basketData.lastModifiedItem;

    if (!basketData || !lastModifiedItem) return;

    onComplete(basketData);
    setHasUserAddedProductToBasket(true);
  };

  const { addItemToBasket, loading } = useAddToBasket(onCompletedCallback);

  const submit = async (sku: string) => {
    const basketId = getCookie(COOKIES.basketId);
    await addItemToBasket({
      variables: {
        basketId,
        sku,
        quantity: 1,
      },
    });
  };

  const showBasket = () => {
    setCurrentProductAndPlanData(null);
    setShowBasket(true);
  };

  const handleOptionSubmit = async (formData: any) => {
    const sku = formData['sku'];
    await submit(sku);
    if (variant === 'product') {
      showBasket();
    }
  };

  const handleSingleSubmit = async (sku: string) => {
    await submit(sku);
    if (variant === 'product') {
      showBasket();
    }
  };

  const getFormattedPrice = (plan: any) =>
    formatPrice(plan.offerPrice || plan.rrpPrice, plan.currency);

  const handleSelectedPlanChange = (plan: IProductPlan) => {
    setIsSubscriptionSelected(isSubscription(plan));
  };

  const getLabel = (sku: string, plan: IProductPlan) => {
    if (isSubscription(plan)) return 'Subscribe';
    if (sku.includes('vitl-gift-card')) {
      return 'Gift amount';
    }
    return 'One-off';
  };

  if (!productPlans || !productPlans.length) {
    return <div className={styles.outOfStock}>{OUT_OF_STOCK}</div>;
  }

  return (
    <div className={styles.addToBasket} data-testid="plan-selector">
      {productPlans?.length === 1 && (
        <div>
          <p
            className={`${styles.priceWrapper} ${variant === 'product' ? styles.alignToLeft : ''}`}
          >
            {productPlans[0].offerPrice && (
              <span className={styles.oldPrice}>
                <del>{formatPrice(productPlans[0].rrpPrice, productPlans[0].currency)}</del>
              </span>
            )}
            <span className={styles.price}>
              {formatPrice(
                productPlans[0].offerPrice || productPlans[0].rrpPrice,
                productPlans[0].currency
              )}
            </span>
            <p className={styles.singleSubscriptionBadge}>{shopText.free_uk_delivery}</p>
          </p>
          <SubmitButton
            buttonLabel={shopText.add_to_basket}
            loading={loading}
            onClick={() => handleSingleSubmit(productPlans[0].sku)}
            className={variant === 'product' ? styles.alignToLeft : ''}
          />
        </div>
      )}
      {productPlans && productPlans.length > 1 && (
        <form onSubmit={handleSubmit(handleOptionSubmit)}>
          <div className={`${styles.radioGroup} ${styles.planSelect}`}>
            {productPlans.map((plan, index: number) => (
              <Fragment key={plan.sku}>
                <label className={styles.radioContainer}>
                  <input
                    type="radio"
                    name="sku"
                    value={plan.sku}
                    ref={register({ required: true })}
                    onChange={() => handleSelectedPlanChange(plan)}
                  />
                  <span className={styles.checkmark} />

                  <div
                    className={`${styles.radioLabel} ${!isSubscription(plan) ? styles.noGap : ''}`}
                  >
                    <span className={styles.label}>{getLabel(plan.sku, plan)}</span>
                    <span>{getFormattedPrice(plan)}</span>
                  </div>
                </label>
                {isSubscription(plan) && isSubscriptionSelected ? (
                  <SubscriptionBenefits plan={plan} />
                ) : null}
                {(plan.planLabel === SUBSCRIPTION_PLANS.single ||
                  plan.planLabel === SUBSCRIPTION_PLANS.oneOff) &&
                !isSubscriptionSelected ? (
                  <div className={styles.badges}>
                    <p>{shopText.free_uk_delivery}</p>
                  </div>
                ) : null}
                {index === 0 && <hr className={styles.horizontalLine} />}
              </Fragment>
            ))}
          </div>
          <SubmitButton
            buttonLabel={shopText.add_to_basket}
            loading={loading}
            fullWidth={variant === 'product'}
          />
        </form>
      )}
    </div>
  );
};

export default AddToBasketNew;
