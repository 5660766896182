import React, { useContext } from 'react';

import SidebarSearch from './sidebar-search';
import MobileMenuLinks from './mobile-menu-links';
import { GlobalContext } from '@store/global-state';
import SidebarBanner from './sidebar-banner/sidebar-banner';

const MobileSidebarMenu = () => {
  const { searchValue } = useContext(GlobalContext);
  return (
    <div>
      <SidebarSearch />
      {searchValue ? null : (
        <>
          <SidebarBanner />
          <MobileMenuLinks />
        </>
      )}
    </div>
  );
};

export default MobileSidebarMenu;
