import { GRAPH_QL_ERROR_TYPES } from '@src/constants';
import { COOKIES, COOKIE_EXPIRES_IN } from '@src/constants/cookies';
import { BASKET_ADD_COUPON } from '@queries/basket';
import { setCookie } from '@src/services/cookies';
import { formatGraphQLError } from '@src/services/format';
import { GlobalContext } from '@src/store/global-state';
import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { updateCachedBasket } from './useBasket';
import { ApolloError } from 'apollo-boost';
import couponCodeService from '@src/utils/couponCodeService';

interface AddCouponProps {
  showMessageModals?: boolean;
  onCompleteCallback?: (data: any) => void;
  onErrorCallback?: (error: ApolloError) => void;
}

export const useAddCoupon = ({
  showMessageModals = true,
  onCompleteCallback,
  onErrorCallback,
}: AddCouponProps) => {
  const {
    setErrorModalMessage,
    setShowSpecialRequirementCoupon,
    setSpecialRequirementCoupon,
    setShowBasket,
    setMessageModal,
    setShowAddCoupon,
  } = useContext(GlobalContext);

  const { removeOffer } = couponCodeService();

  const [addCoupon, { loading, error }] = useMutation(BASKET_ADD_COUPON, {
    update: (cache, response) => {
      if (!response.data) return;
      updateCachedBasket(response.data.basket_addCoupon);
    },
    onCompleted(res) {
      if (res?.basket_addCoupon?.basketId) {
        setCookie(COOKIES.basketId, res.basket_addCoupon.basketId, COOKIE_EXPIRES_IN.thirtyDays);
      }

      if (res.basket_addCoupon) {
        removeOffer();
        if (showMessageModals) {
          setMessageModal({
            visible: true,
            title: 'Coupon applied',
            message: 'Coupon successfully applied!',
            buttonLabel: 'Ok',
          });
        }
        if (res.basket_addCoupon.items?.length) {
          setShowAddCoupon(false);
          setShowBasket(true);
          onCompleteCallback && onCompleteCallback(res);
        }
      }
    },
    onError(error) {
      const couponRequiresAccountError = error.graphQLErrors.find(
        (error: any) =>
          error.errorType === GRAPH_QL_ERROR_TYPES.CouponRequiresAccount ||
          error.errorType === GRAPH_QL_ERROR_TYPES.CouponRequiresHealthProfile
      );

      const couponValidFirstOrderOnly = error.graphQLErrors.find(
        (error: any) => error.errorType === GRAPH_QL_ERROR_TYPES.CouponValidFirstOrderOnly
      );

      if (couponRequiresAccountError) {
        setShowAddCoupon(false);
        setShowSpecialRequirementCoupon(true);
        setSpecialRequirementCoupon({
          type: couponRequiresAccountError?.errorType,
          message: couponRequiresAccountError?.message,
        });
      }

      if (couponValidFirstOrderOnly) {
        setShowSpecialRequirementCoupon(true);
        setSpecialRequirementCoupon({
          type: couponValidFirstOrderOnly?.errorType,
          message: couponValidFirstOrderOnly?.message,
        });
      }

      if (showMessageModals) {
        setErrorModalMessage(error.message && formatGraphQLError(error.message));
      }
      onErrorCallback && onErrorCallback(error);
    },
  });
  return { addCoupon, loading, error };
};
