import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
//components
import Loader from '../loader';
// styles
import styles from './modal.module.scss';

interface IProps {
  type?: string;
  children: any;
  handleClose?: () => void;
  loading?: boolean;
}

const Modal: React.FC<IProps> = ({ type = '', children, handleClose, loading = false }) => {
  const modalRoot: HTMLElement | null = document.getElementById('modalContainer');

  useEffect(() => {
    document.body.classList.add('body-fixed');

    return () => {
      document.body.classList.remove('body-fixed');
    };
  });

  const modalContent = (
    <div className={styles.wrapper}>
      {loading && <Loader />}
      <div className={styles['container' + type]}>
        {handleClose && (
          <button
            data-testid="modal-button-close"
            type="button"
            className={styles.buttonClose}
            onClick={handleClose}
          >
            <img src={'/images/icon_close.svg'} alt="Close" />
          </button>
        )}
        {children}
      </div>
    </div>
  );

  return modalRoot ? ReactDOM.createPortal(modalContent, modalRoot) : null;
};

export default Modal;
