import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { graphql, useStaticQuery } from 'gatsby';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { navigate } from '@reach/router';
//context
import { GlobalContext } from '@store/global-state';
//services
import { getPriceForAddOrRemoveItem } from '@services/tracking/ga';
import { getCookie, setCookie } from '@services/cookies';
import { formatPrice } from '@services/format';
import {
  getEventId,
  trackEvent,
  trackFacebook,
  trackHeapEvent,
  trackPinterest,
  trackTiktok,
} from '@services/tracking/tracking';
import { isUsingGBPCurrency } from '@services/global';
import { shouldClearBasket } from '@services/basket';
import couponCodeService from '@src/utils/couponCodeService';
import useClickedOutside from '@hooks/useClickedOutside';
// queries
import { BASKET_REMOVE_COUPON, BASKET_REMOVE_ITEM, BASKET_SET_ITEM_QTY } from '@queries/basket';
// components
import { Icon } from 'vitl-component-library';
import PaymentCardsIcons from '../paymentCardsIcons/paymentCardsIcons';
import SubmitButton from '../submit-button';
import Loader from '../loader';
import CustomInput from '../form/custom-input';
import CustomCheckbox from '../form/custom-checkbox';
import Error from '../error/error';
import BasketDeliveryDetails from './components/BasketDeliveryDetails';
import BasketRecommendedProducts from './components/basket-recommended-products/BasketRecommendedProducts';
import BasketItem from './components/BasketItem';
import EstimateDeliveryModal from '@components/basket/components/EstimateDeliveryModal';
//hooks
import { useWallet } from '@hooks/usePay';
import { updateCachedBasket, useBasket } from '@hooks/useBasket';
import useRestrictedItems from '@hooks/useRestrictedItems';
import useCrossSellProducts from '@hooks/useCrossSellProducts';
import { useAddCoupon } from '@hooks/useAddCoupon';
//constants
import { ERROR_MESSAGES } from '@src/constants';
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';
//styles
import styles from './basket.module.scss';
// types
import { IBasketRemoveItemMutationResponse } from '../../types/mutation-response';
import { IBasketItem } from '../../types/basket';

const Basket = () => {
  const [isBasketLoading, setIsBasketLoading] = useState(false);
  const [lastAction, setLastAction] = useState('');
  const [lastModifiedItem, setLastModifiedItem] = useState<IBasketItem | null>(null);
  const [isBasketViewEventTracked, setIsBasketViewEventTracked] = useState(false);
  const { setCoupon, getOffer, removeOffer } = couponCodeService();
  const { directusCheckout } = useStaticQuery(graphql`
    query {
      directusCheckout {
        cart_title
        cart_total_label
        cart_pay_card_label
        cart_add_coupon_label
        cart_add_coupon_button_label
      }
    }
  `);

  const {
    setErrorModalMessage,
    hasUserAddedProductToBasket,
    setHasUserAddedProductToBasket,
    setShowCrossSellSingle,
    isLoggedIn,
    setShowBasket,
    showBasket,
  } = useContext(GlobalContext);

  const basketId = getCookie(COOKIES.basketId);

  const { restricted, countries, country, checkProductRestrictions } = useRestrictedItems();

  const { usePopupCrossSellProducts, useBasketCrossSellProducts } = useCrossSellProducts();

  const crossSellBasketPopupProduct = usePopupCrossSellProducts();

  const onBasketLoaded = () => {
    if (hasUserAddedProductToBasket && crossSellBasketPopupProduct) {
      setShowCrossSellSingle(true);
    }
  };

  const { data: basketData, loading: loadingBasket } = useBasket('network-only', onBasketLoaded);

  const {
    paymentRequest: walletPR,
    loading: isLoadingWalletPR,
    updatePaymentRequest: updateWalletPR,
  } = useWallet(basketData);

  const onCouponCodeAdded = () => {
    setShowAddCoupon(false);
  };

  const { addCoupon, loading: addCouponLoading, error: addCouponError } = useAddCoupon({
    showMessageModals: false,
    onCompleteCallback: onCouponCodeAdded,
  });

  const [basketRemoveItem, { loading: removeLoading }] = useMutation<
    IBasketRemoveItemMutationResponse
  >(BASKET_REMOVE_ITEM, {
    update: (cache, response) => {
      setHasUserAddedProductToBasket(false);
      if (!response.data) return;
      updateCachedBasket(response.data.basket_removeItem);
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.removeItemFromCart);
    },
    onCompleted: data => {
      trackEvent('RemoveFromCart', {
        value: getPriceForAddOrRemoveItem(lastModifiedItem),
        currency: basketData?.currency,
        items: [lastModifiedItem],
      });
    },
  });

  const [setItemQuantity, { loading: itemQuantityLoading }] = useMutation(BASKET_SET_ITEM_QTY, {
    update: (cache, response) => {
      if (!response.data) return;
      updateCachedBasket(response.data.basket_setItemQuantity);
    },
    onCompleted: () => {
      const eventName = lastAction === 'add' ? 'AddToCart' : 'RemoveFromCart';
      trackEvent(eventName, {
        value: getPriceForAddOrRemoveItem(lastModifiedItem),
        currency: basketData?.currency,
        items: [lastModifiedItem],
      });
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.setItemQuantity);
    },
  });

  const trackedProducts =
    basketData?.items &&
    basketData.items.map((item: any, index: number) => ({
      product_id: item.id,
      name: item.name,
      price: item.singleItemCost,
      quantity: item.qty,
      image_url: item.image,
      position: index,
    }));

  const [removeCoupon, { loading: loadingRemoveCoupon }] = useMutation(BASKET_REMOVE_COUPON, {
    update: (cache, response) => {
      if (!response.data) return;
      updateCachedBasket(response.data.basket_removeCoupon);
    },
    onCompleted() {
      removeOffer();
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.removeCoupon);
    },
  });

  const [isEstimatedDeliveryModalVisible, setIsEstimatedDeliveryModalVisible] = useState(false);

  // state
  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm();

  const { register: registerTerms, getValues: getValuesTerms } = useForm();

  const [showAddCoupon, setShowAddCoupon] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState();
  const [showAddReferee, setShowAddReferee] = useState(true);
  const [closingBasket, setClosingBasket] = useState(false);

  const hasFreeShippingOffer =
    isUsingGBPCurrency() &&
    basketData &&
    basketData.totalWithBalance - basketData.shipping < 20.01 &&
    basketData.shipping !== 0;

  const shouldShowRemoveDiscount =
    basketData?.discountCode && basketData?.discountCode !== 'DISCOUNT';

  const discountLabel = basketData?.discountCode ? basketData?.discountCode : 'DISCOUNT';

  const onTermsAcceptedChange = () => setTermsAccepted(getValuesTerms('termsOfService'));
  const onPayButtonClick = async () => {
    const eventId = getEventId();
    // review this after all the events from rudderstack are migrated and delete unnecessary
    trackEvent(
      'InitiateCheckout',
      {
        order_id: basketId,
        value: basketData?.totalWithBalance,
        revenue: basketData?.subTotal,
        shipping: basketData?.shipping,
        discount: basketData?.discountAmount,
        coupon: basketData?.discountCode,
        currency: basketData?.currency,
        products: trackedProducts,
        items: basketData?.items,
      },
      undefined,
      eventId
    );
    trackFacebook(
      'InitiateCheckout',
      {
        cart_id: basketId,
        currency: basketData?.currency,
        discount: basketData?.discountAmount,
        products: trackedProducts,
        rrp: basketData?.subTotal,
        shipping: basketData?.shipping,
        total: basketData?.totalWithBalance,
        coupon: basketData?.discountCode,
      },
      eventId
    );
    trackTiktok(
      'InitiateCheckout',
      {
        content_id: basketId,
        currency: basketData?.currency,
        value: basketData?.totalWithBalance,
      },
      eventId
    );
    // InitiateCheckout event for Pinterest can be used as a custom user-defined, reporting is not available for this kind of events
    // instead of that we can use Custom event name which can be used for conversion reporting
    trackPinterest('Custom', {
      order_id: basketId,
      currency: basketData?.currency,
      promo_code: basketData?.discountCode,
      value: basketData?.totalWithBalance,
    });
    setShowBasket(false);
    navigate(process.env.GATSBY_CHECKOUT_URL!);
  };

  const handleRemoveItem = async (id: string) => {
    await basketRemoveItem({
      variables: {
        basketId: getCookie(COOKIES.basketId),
        itemId: id,
      },
    });
  };

  const handleItemQuantityChange = async (id: string, qty: number, action: string) => {
    setLastAction(action);
    try {
      if (qty === 0) {
        const removeItemCartData = await basketRemoveItem({
          variables: {
            basketId: getCookie(COOKIES.basketId),
            itemId: id,
          },
        });
        const updatedCart = removeItemCartData?.data?.basket_removeItem;
        updateWalletPR(updatedCart);
      } else {
        const setItemQuantityData = await setItemQuantity({
          variables: {
            itemId: id,
            quantity: qty,
            basketId: basketId,
          },
        });
        const updatedBasket = setItemQuantityData.data.basket_setItemQty;
        updateWalletPR(updatedBasket);
      }
    } catch {}
  };

  const onSubmit = (formData: any) => {
    const coupon = formData.couponCode;

    trackEvent('Coupon Entered', {
      cart_id: basketId,
      coupon_id: coupon,
    });

    addCoupon({
      variables: {
        basketId: getCookie(COOKIES.basketId),
        couponCode: coupon,
      },
    });
  };

  useEffect(() => {
    document.body.classList.add('basket-open');
    return () => {
      document.body.classList.remove('basket-open');
    };
  }, []);

  useEffect(() => {
    const couponCode = getOffer('coupon');
    if (couponCode) {
      addCoupon({
        variables: {
          basketId: getCookie(COOKIES.basketId),
          couponCode,
        },
      });
    }
    return () => {
      setHasUserAddedProductToBasket(false);
      setClosingBasket(false);
      showBasket && setShowBasket(false);
    };
  }, []);

  useEffect(() => {
    if (!basketId && basketData?.basketId) {
      setCookie(COOKIES.basketId, basketData.basketId, COOKIE_EXPIRES_IN.thirtyDays);
      checkProductRestrictions();
      return;
    }
    basketId && checkProductRestrictions();
  }, [basketId, basketData]);

  useEffect(() => {
    if (isBasketViewEventTracked || !basketData || !trackedProducts) {
      return;
    }
    trackEvent('Basket Viewed', {
      cart_id: basketId,
      value: basketData.totalWithBalance,
      shipping: basketData.shipping,
      discount: basketData.discountAmount,
      coupon: basketData.discountCode,
      currency: basketData.currency,
      products: trackedProducts,
    });
    trackHeapEvent('basket_open');
    setIsBasketViewEventTracked(true);
  }, [basketData, trackedProducts, isBasketViewEventTracked]);

  const handleCloseBasket = () => {
    setClosingBasket(true);
    setTimeout(() => {
      setClosingBasket(false);
      setShowBasket(false);
    }, 150);
  };

  const basketElemRef = useRef(null);
  const outsideElementClicked = (event: any) => {
    if (event.target.classList.contains('basket-overlay')) {
      handleCloseBasket();
    }
  };
  useClickedOutside(basketElemRef, outsideElementClicked);

  const loading =
    loadingBasket ||
    itemQuantityLoading ||
    loadingRemoveCoupon ||
    isBasketLoading ||
    removeLoading ||
    isLoadingWalletPR;

  return (
    <div className={closingBasket ? styles.closingBasket : ''}>
      <div className={`${styles.basketContainer} basket-overlay`}></div>
      <div
        data-testid="basket"
        className={loading ? styles.basketLoading : styles.basket}
        ref={basketElemRef}
      >
        {loading && <Loader isDark={true} hasContainer={true} />}
        <h2 className={styles.h2}>{directusCheckout.cart_title}</h2>
        <button type="button" className={styles.buttonClose} onClick={handleCloseBasket}>
          <img src={'/images/icon_close.svg'} alt="" />
        </button>
        {basketData && !isLoadingWalletPR && (
          <div>
            {basketData.items.length > 0 && !shouldClearBasket() ? (
              <div>
                <ul className={styles.itemsList}>
                  {basketData.items.map(item => (
                    <BasketItem
                      key={item.id}
                      item={item}
                      onRemove={handleRemoveItem}
                      onQuantityChange={handleItemQuantityChange}
                      isRestricted={restricted ? restricted.includes(item.id) : false}
                      country={country}
                      currency={basketData.currency}
                      isLoading={itemQuantityLoading}
                      setLastModifiedItem={setLastModifiedItem}
                    />
                  ))}
                </ul>
                <BasketRecommendedProducts
                  setLoadingBasket={setIsBasketLoading}
                  getCrossSellBasketProductsBasket={useBasketCrossSellProducts}
                />
                <div className={styles.priceAndDelivery}>
                  {basketData?.deliveryAddress?.countryId ? (
                    <BasketDeliveryDetails
                      basket={basketData}
                      currency={basketData.currency}
                      countries={countries}
                    />
                  ) : null}

                  {basketData.discountAmount ? (
                    <div className={styles.rowBasketDetails}>
                      <p className={styles.discountCode}>
                        {discountLabel}
                        {shouldShowRemoveDiscount && (
                          <span
                            className={styles.removeCouponIcon}
                            data-testid="basket-remove-coupon"
                            onClick={() => {
                              removeCoupon({
                                variables: {
                                  basketId: getCookie(COOKIES.basketId),
                                },
                              });
                            }}
                          >
                            <img src={'/images/icon_close-gray.svg'} alt="" />
                          </span>
                        )}
                      </p>
                      <p className={styles.noWrap}>
                        - {formatPrice(basketData.discountAmount, basketData.currency)}
                      </p>
                    </div>
                  ) : null}

                  {basketData.creditAllocated && basketData.creditAllocated > 0 ? (
                    <div className={styles.rowBasketDetails}>
                      <p>Credit</p>
                      <p>- {formatPrice(basketData.creditAllocated, basketData.currency)}</p>
                    </div>
                  ) : null}

                  <div className={styles.rowBasketDetails}>
                    <p>
                      {basketData?.deliveryAddress?.countryId
                        ? directusCheckout.cart_total_label
                        : 'Subtotal'}
                    </p>
                    <p>{formatPrice(basketData.totalWithCreditAllocated, basketData.currency)}</p>
                  </div>
                </div>
                <div className={styles.bannersAndTerms}>
                  {hasFreeShippingOffer && (
                    <div className={styles.shippingQualified}>
                      <img src="/images/icon-error.svg" width="20" />
                      <p>
                        Spend over £20 to qualify for <span>free</span> UK shipping
                      </p>
                    </div>
                  )}

                  {!isLoggedIn && (
                    <div className={styles.termsWrapper}>
                      <form onChange={() => onTermsAcceptedChange()}>
                        <CustomCheckbox
                          label={
                            <span>
                              I have read and accept the&nbsp;
                              <a
                                href="/terms"
                                target="_blank"
                                className={styles.buttonLinkNoMargin}
                              >
                                Terms of Service
                              </a>
                            </span>
                          }
                          id="termsOfService"
                          name="termsOfService"
                          register={registerTerms()}
                          disabled={false}
                        />
                      </form>
                    </div>
                  )}
                </div>
                <div className={styles.payOptions}>
                  <div className={styles.payOption}>
                    <SubmitButton
                      fullWidth
                      loading={loading}
                      buttonLabel={directusCheckout.cart_pay_card_label}
                      onClick={onPayButtonClick}
                      disabled={!isLoggedIn && !termsAccepted}
                    />
                  </div>
                  {walletPR && (
                    <div
                      className={
                        !isLoggedIn && !termsAccepted
                          ? styles.walletOptionDisabled
                          : styles.walletOption
                      }
                    >
                      <PaymentRequestButtonElement
                        options={{
                          paymentRequest: walletPR,
                          style: {
                            paymentRequestButton: {
                              type: 'default',
                              height: '50px',
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
                <PaymentCardsIcons style={{ display: 'none' }} />
                <div className={styles.addCoupon}>
                  {!showAddCoupon && (
                    <button
                      type="button"
                      data-testid="basket-add-coupon"
                      className={styles.buttonLink}
                      onClick={() => setShowAddCoupon(true)}
                    >
                      {directusCheckout.cart_add_coupon_label}
                    </button>
                  )}
                  {showAddCoupon && (
                    <div>
                      <form className={styles.addCouponForm} onSubmit={handleSubmit(onSubmit)}>
                        <fieldset disabled={addCouponLoading}>
                          <CustomInput
                            id="couponCode"
                            register={register({ required: true })}
                            label="Promo code"
                            isDirty={dirtyFields.couponCode}
                            error={!addCouponError && errors.couponCode}
                            errorMessage="Please enter promo code"
                            loading={addCouponLoading}
                          />
                          {addCouponLoading ? null : (
                            <button
                              className={styles.addCouponButton}
                              type="submit"
                              disabled={addCouponLoading}
                            >
                              {directusCheckout.cart_add_coupon_button_label}
                            </button>
                          )}
                        </fieldset>
                        <div className={styles.addCouponButtonWrap}>
                          <button
                            className={styles.couponButton}
                            onClick={() => setShowAddCoupon(false)}
                            disabled={addCouponLoading}
                          >
                            Close <Icon icon="close" width={10} />
                          </button>
                        </div>
                      </form>
                      <Error data={addCouponError} noMargin />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p className={styles.noItems}>There are no items in your basket</p>
            )}
          </div>
        )}
        <EstimateDeliveryModal
          isEstimatedDeliveryModalVisible={isEstimatedDeliveryModalVisible}
          setIsEstimatedDeliveryModalVisible={setIsEstimatedDeliveryModalVisible}
        />
      </div>
    </div>
  );
};

export default Basket;
