import React, { useContext } from 'react';

import { GlobalContext } from '@store/global-state';
import AddToBasketNew from '../add-to-basket-new/add-to-basket-new';
import { useShopText } from '@features/shop/hooks/useShopText';
//types
import { IBasket } from '@src/types/basket';
//styles
import styles from './step-one.module.scss';

interface IProps {
  onComplete: (basketData: IBasket) => void;
}

const StepOne = ({ onComplete }: IProps) => {
  const shopText = useShopText();
  const { currentProductAndPlanData } = useContext(GlobalContext);

  const isSubscription =
    currentProductAndPlanData && currentProductAndPlanData?.plan?.product_plans?.length > 1;

  const image = currentProductAndPlanData?.product.isImageFromMarketingDirectus
    ? `${process.env.GATSBY_DIRECTUS_MARKETING_MEDIA_URL}/${currentProductAndPlanData?.product.filename_disk}`
    : `${process.env.GATSBY_MEDIA_URL}${currentProductAndPlanData?.product.filename_disk}`;

  return (
    <div className={styles.choosePlan}>
      {isSubscription && <h4>{shopText.choose_your_plan}</h4>}
      <div className={styles.choosePlanWrap}>
        <div className={styles.left}>
          <img src={image} alt="" />
          <p>{currentProductAndPlanData?.product.name}</p>
        </div>
        <div className={styles.right}>
          <AddToBasketNew
            onComplete={onComplete}
            productPlans={currentProductAndPlanData?.plan.product_plans}
          />
        </div>
      </div>
    </div>
  );
};

export default StepOne;
