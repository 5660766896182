import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { PRODUCT_PLANS } from '@queries/product';
import { DURATION_10_MINUTES } from '@src/constants/time';
import { IProductPlansQueryResponse } from '../types/query-response';
import { getCurrency } from '../utils/currency';

export const useProductPlansLazyQuery = (options: any) =>
  useLazyQuery<IProductPlansQueryResponse>(PRODUCT_PLANS, {
    ...options,
    variables: {
      currencyCacheKey: getCurrency(),
    },
  });

export const useProductPlansNewQuery = (options: any) =>
  useQuery<IProductPlansQueryResponse>(PRODUCT_PLANS, {
    ...options,
    variables: {
      ...options.variables,
      currencyCacheKey: getCurrency(),
    },
    pollInterval: DURATION_10_MINUTES,
  });
