import { useContext } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useLocation } from '@reach/router';
//queries
import { CONTENT_BANNER } from '@queries/banner';
//context
import { GlobalContext } from '@store/global-state';

export const useContentBanner = () => {
  const { setBannerData, setBannerHeight } = useContext(GlobalContext);
  const { pathname } = useLocation();

  const [getContentBanner, { data, loading, error }] = useLazyQuery(CONTENT_BANNER, {
    variables: {
      path: pathname,
    },
    onCompleted() {
      setBannerHeight(0);
      setBannerData(data.content_banner);
    },
  });
  return { getContentBanner, data, loading, error };
};
