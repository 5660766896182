import { useLazyQuery } from 'react-apollo';
import { useContext } from 'react';
//queries
import { FEATURE_FLAGS } from '@queries/feature-flags';
//context
import { GlobalContext } from '@store/global-state';

export const useFeatureFlags = () => {
  const { setFeatureFlags } = useContext(GlobalContext);
  const [getFeatureFlags, { data: dataFeatureFlags, loading: loadingFeatureFlags }] = useLazyQuery(
    FEATURE_FLAGS,
    {
      onCompleted(data) {
        const featureFlags = data.config_featureFlags;

        setFeatureFlags({
          apple: featureFlags.includes('apple_pay'),
          google: featureFlags.includes('google_pay'),
        });
      },
    }
  );

  return { getFeatureFlags, loadingFeatureFlags, dataFeatureFlags };
};
