import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { setCookie } from '../../services/cookies';
// context
import { GlobalContext } from '@store/global-state';
// components
import Image from '@components/image/image';
// styles
import styles from './utm-modal.module.scss';
// constants
import { COOKIE_EXPIRES_IN } from '@constants/cookies';

interface IProps {
  type: string;
  image: string;
  redirectPath: string;
}

const UtmModal: React.FC<IProps> = ({ type, image, redirectPath }) => {
  const { setUtmModalData } = useContext(GlobalContext);

  const handleClose = () => {
    setCookie('utm-modal-' + type, 'true', COOKIE_EXPIRES_IN.threeDays);

    setUtmModalData(null);
  };

  const handleClick = (path: string) => {
    handleClose();
    navigate(path);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.image} onClick={() => handleClick(redirectPath)}>
          <Image filename={image} width={375} />
        </div>
        <button type="button" className={styles.buttonClose} onClick={handleClose}>
          <img src={'/images/icon_close.svg'} alt="Close" />
        </button>
      </div>
    </div>
  );
};

export default UtmModal;
