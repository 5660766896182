import React, { FC } from 'react';
import { Badge } from 'vitl-component-library';
import styles from '@components/basket/basket.module.scss';
// interfaces
import { IBasketItem, IBasketItemOffer } from '../../../types/basket';
import { OFFER_DURATION_TYPES, OFFER_TYPES } from '@constants/enums';
import { formatPrice } from '@services/format';

interface IProps {
  item: IBasketItem;
  offer: IBasketItemOffer;
  currency: string;
}

const BasketItemOfferBadge: FC<IProps> = ({ item, offer, currency }) => {
  const hasDiscount = offer.discount;
  const hasMoreThenOneOffer = item.offersApplied.length > 1;

  const hasOneTimeOffer =
    (!offer.offerRedemptions && offer.offerDurationType === OFFER_DURATION_TYPES.Once) ||
    offer.offerRedemptions === 1;

  const isFreeTrialOffer = offer.offerType === OFFER_TYPES.FreeTrial;
  const isFixedOffer =
    offer.offerType === OFFER_TYPES.Fixed || offer.offerType === OFFER_TYPES.CartFixed;

  const renderBadgeText = () => {
    if (hasDiscount) {
      const discount = isFixedOffer ? formatPrice(offer.discount, currency) : `${offer.discount}%`;
      if (hasOneTimeOffer) {
        return `${discount} off first order`;
      }
      if (offer.offerRedemptions) {
        return `${discount} off first ${offer.offerRedemptions} orders`;
      }
      return `${discount} off`;
    }
    return null;
  };

  return (
    <div className={styles.itemOffers}>
      {hasMoreThenOneOffer ? (
        <Badge isSmall={true} label="OFFERS APPLIED" />
      ) : (
        offer &&
        !isFreeTrialOffer && (
          <>
            <Badge isSmall={true} label={'OFFER'} />
            <span className={styles.discountPercentage}>{renderBadgeText()}</span>
          </>
        )
      )}
    </div>
  );
};
export default BasketItemOfferBadge;
