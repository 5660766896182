import { COOKIE_DATA, PRODUCT_COOKIES } from '@src/constants';
import { COOKIES, COOKIE_EXPIRES_IN } from '@src/constants/cookies';
import { getCookie, removeCookie, setCookie } from '@src/services/cookies';

export const pPfreeTrialSetCookie = (
  partnership: string = PRODUCT_COOKIES.PERSONALISED_PLUS.value.FREE_TRIAL
) => {
  setCookie(COOKIES.personalisedPlusPartnership, partnership, COOKIE_EXPIRES_IN.sevenDays);
};

export const pPfiftyPercentOffSetCookie = () => {
  setCookie(
    COOKIES.personalisedPlusPartnership,
    PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_PERCENT_OFF,
    COOKIE_EXPIRES_IN.sevenDays
  );
  setCookie(
    COOKIES.couponCode,
    COOKIE_DATA['50-off-personalised-one-month'].couponCode,
    COOKIE_EXPIRES_IN.sevenDays
  );
};

export const pPfiftyFivePercentOffSetCookie = () => {
  setCookie(
    COOKIES.personalisedPlusPartnership,
    PRODUCT_COOKIES.PERSONALISED_PLUS.value.FIFTY_FIVE_PERCENT_OFF,
    COOKIE_EXPIRES_IN.sevenDays
  );
  setCookie(
    COOKIES.couponCode,
    COOKIE_DATA['55-off-personalised-one-month'].couponCode,
    COOKIE_EXPIRES_IN.sevenDays
  );
};

export const pPthirtyPercentOffSetCookie = () => {
  setCookie(
    COOKIES.personalisedPlusPartnership,
    PRODUCT_COOKIES.PERSONALISED_PLUS.value.THIRTY_OFF_TREE_MONTH,
    COOKIE_EXPIRES_IN.sevenDays
  );
  setCookie(COOKIES.couponCode, COOKIE_DATA['30-off-3'].couponCode, COOKIE_EXPIRES_IN.sevenDays);
};

export const pPSeventyPercentOffSetCookie = () => {
  setCookie(
    COOKIES.personalisedPlusPartnership,
    PRODUCT_COOKIES.PERSONALISED_PLUS.value.SEVENTY_OFF,
    COOKIE_EXPIRES_IN.sevenDays
  );
  setCookie(
    COOKIES.couponCode,
    COOKIE_DATA['70-off-personalised-one-month'].couponCode,
    COOKIE_EXPIRES_IN.sevenDays
  );
};

export const pPPartnershipSetCookie = (partnership: string) => {
  setCookie(COOKIES.essentialOnePartnership, partnership, COOKIE_EXPIRES_IN.sevenDays);
};

export const essentialAffiliateSetCookie = () => {
  setCookie(
    COOKIES.essentialOnePartnership,
    PRODUCT_COOKIES.ESSENTIAL_ONE.value.AFFILIATES,
    COOKIE_EXPIRES_IN.sevenDays
  );
};

export const essential20OffSetCookie = () => {
  setCookie(
    COOKIES.essentialOnePartnership,
    PRODUCT_COOKIES.ESSENTIAL_ONE.value.ESSENTIAL_ONE_20_OFF_FREE_TRIAL,
    COOKIE_EXPIRES_IN.sevenDays
  );
};

export const essentialReferalSetCookie = () => {
  setCookie(
    COOKIES.essentialOnePartnership,
    PRODUCT_COOKIES.ESSENTIAL_ONE.value.REFERRAL,
    COOKIE_EXPIRES_IN.sevenDays
  );
};

export const essentialFreeTrialSetCookie = () => {
  setCookie(
    COOKIES.essentialOnePartnership,
    PRODUCT_COOKIES.ESSENTIAL_ONE.value.FREE_TRIAL,
    COOKIE_EXPIRES_IN.sevenDays
  );
};

export const removePersonalizedPageVisitSetCookie = () => {
  removeCookie(COOKIES.personalizedPageType);
};

export const essentialPageVisitSetCookie = () => {
  removePersonalizedPageVisitSetCookie();
  setCookie(COOKIES.personalizedPageType, 'eo', COOKIE_EXPIRES_IN.thirtyDays);
};

export const fourPPPageVisitSetCookie = () => {
  removePersonalizedPageVisitSetCookie();
  setCookie(COOKIES.personalizedPageType, '4pp', COOKIE_EXPIRES_IN.thirtyDays);
};

export const getPersonalizedPageVisitSetCookie = () => {
  return getCookie(COOKIES.personalizedPageType);
};
