import React, { useContext, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { GlobalContext } from '@store/global-state';
import { SIDEBAR_ANIMATION, SIDEBAR_INNER_ANIMATION } from './constants';
import useClickedOutside from '@hooks/useClickedOutside';
import { SIDEBAR_VIEWS } from './constants';

import styles from './sidebar.module.scss';

interface IProps {
  children: React.ReactNode;
}

const Sidebar = ({ children }: IProps) => {
  const { sidebar, setSidebar } = useContext(GlobalContext);
  const elementRef = useRef(null);

  const outsideElementClicked = () => {
    if (sidebar) {
      setSidebar(SIDEBAR_VIEWS.empty);
    }
  };

  useClickedOutside(elementRef, outsideElementClicked);

  useEffect(() => {
    document.body.classList.add('body-fixed');

    if (!sidebar) {
      setTimeout(function () {
        document.body.classList.remove('body-fixed');
      }, 300);
    }

    return () => {
      document.body.classList.remove('body-fixed');
    };
  }, [sidebar]);

  return (
    <AnimatePresence>
      {sidebar && (
        <div className={styles.sidebarWrapper}>
          <motion.aside
            className={styles.sidebar}
            initial="closed"
            animate="open"
            exit="closed"
            ref={elementRef}
            variants={SIDEBAR_ANIMATION}
          >
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={SIDEBAR_INNER_ANIMATION}
              className={styles.sidebarInner}
            >
              <div className={styles.top}>
                <img
                  onClick={() => setSidebar(SIDEBAR_VIEWS.empty)}
                  src="/images/icon_close.svg"
                  width="16px"
                  alt="cross"
                />
              </div>
              {children}
            </motion.div>
          </motion.aside>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;
