import { graphql, useStaticQuery } from 'gatsby';

export const useShopText = () => {
  const { shopText } = useStaticQuery(graphql`
    query ShopText {
      shopText: directusShopText {
        filter_by
        shop_all
        price_from
        back_in_stock_soon
        choose_your_plan
        add_to_basket
        free_uk_delivery
        added_to_your_basket
        keep_shopping
        view_basket
        best_seller
        selling_fast
        low_in_stock
        digital_product
        free_delivery
        pause_or_cancel
        delivered_every_30_days
      }
    }
  `);
  return shopText;
};
