import React, { Dispatch, FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
// services
import { formatPrice } from '@services/format';
import useGetProductPlansData from '@features/shop/hooks/useGetProductPlansData';
import { useBasketUpsellItem } from '@features/shop/hooks/useBasketUpsellItem';
import { getCookie } from '@services/cookies';
import { trackCustomEvent } from '@services/tracking/tracking';
// components
import Image from '@components/image/image';
import Dropdown, { IOption } from '@components/dropdown/dropdown';
import Select from '@components/select/select';
import Loader from '@components/loader';
import BasketItemOfferBadge from '@components/basket/components/BasketItemOfferBadge';
// constants
import { ITEM_QUANTITY_OPTIONS } from '@constants/basket';
import { COOKIES } from '@constants/cookies';
import { CUSTOM_TRACKING_EVENTS, OFFER_DURATION_TYPES, OFFER_TYPES } from '@constants/enums';
// interfaces
import { IBasketItem } from '../../../types/basket';
// styles
import styles from '../basket.module.scss';
import { Badge } from 'vitl-component-library';

interface IBasketItemProps {
  item: IBasketItem;
  onRemove: (id: string) => void;
  onQuantityChange: (id: string, qty: number, action: string) => void;
  isRestricted: boolean;
  country?: string;
  currency: string;
  isLoading: boolean;
  setLastModifiedItem: Dispatch<React.SetStateAction<IBasketItem | null>>;
}

const BasketItem: FC<IBasketItemProps> = ({
  item,
  onRemove,
  onQuantityChange,
  isRestricted,
  country,
  currency,
  isLoading,
  setLastModifiedItem,
}) => {
  const hasMultiMonthOffer = false;
  const hasSubscriptionOffer = item.upsellSku;
  const upsellSavePercentage = Math.round(item.upsellSavePercentage * 100);

  const { allPlans } = useGetProductPlansData();

  const upsellProduct =
    hasSubscriptionOffer && allPlans?.product_plans.find(plan => plan.sku === item.upsellSku);

  const handleItemRemove = () => {
    setLastModifiedItem(item);
    onRemove(item.id);
  };

  const handleQuantityChange = (option: IOption) => {
    const lastModifiedItem = { ...item, qty: option.value } as IBasketItem;
    setLastModifiedItem(lastModifiedItem);
    onQuantityChange(item.id, option.value, option.value === 0 ? 'remove' : 'add');
  };

  const trackPromotionData = {
    promotion_id: `${item.sku}_upsell_to_${item.upsellSku}`,
    creative: `Switch to subscription and save ${upsellSavePercentage}%`,
    name: upsellProduct ? upsellProduct.productName : '',
    position: 'cart_item_bottom',
    fromSku: item.sku,
    toSku: item.upsellSku,
    quantity: item.qty,
  };

  const onUpsellCompletedCallback = () => {
    trackCustomEvent(CUSTOM_TRACKING_EVENTS.BasketUpsellClick, trackPromotionData);
  };

  const { addBasketUpsellItem, loading } = useBasketUpsellItem(onUpsellCompletedCallback);

  const offer = item.offersApplied[0];
  const itemRowTotalPrice = formatPrice(item.rowTotal, currency);
  const itemRowTotalPriceWithDiscount = formatPrice(item.rowTotalWithDiscount, currency);
  const itemHasDiscount = item.rowTotal !== item.rowTotalWithDiscount;
  const isFreeTrialOffer = offer?.offerType === OFFER_TYPES.FreeTrial;
  const isRepeating = offer?.offerDurationType === OFFER_DURATION_TYPES.Repeating;

  const itemDescription = `${
    isFreeTrialOffer || isRepeating ? 'Then ' : ''
  }${itemRowTotalPrice} every ${item.frequencyDays} days`;

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const handleAddUpsellItem = () => {
    addBasketUpsellItem({
      variables: {
        basketId: getCookie(COOKIES.basketId),
        itemId: item.id,
        toSku: item.upsellSku,
      },
    });
  };

  useEffect(() => {
    if (!upsellProduct) return;

    trackCustomEvent(CUSTOM_TRACKING_EVENTS.BasketUpsellView, {
      ...trackPromotionData,
      name: item.productName,
    });
  }, [upsellProduct]);

  return (
    <li key={item.id} className={styles.item} data-testid="basket-item">
      <div className={styles.itemContentWrapper}>
        {loading ? (
          <Loader hasContainer={true} isDark={false} />
        ) : (
          <>
            <div className={styles.itemImage}>
              <Image alt={item.name} src={`${item.image}?w=210`} />
            </div>
            <div className={styles.itemContent}>
              <div
                className={styles.itemDelete}
                onClick={handleItemRemove}
                data-testid={`basket-remove-item-${item.productName}`}
              >
                <Image
                  src={'/images/icon-trashcan.svg'}
                  width={16}
                  className={styles.tickIcon}
                  alt="Delete item"
                />
              </div>

              <p data-testid="basket-item-title" className={styles.itemTitle}>
                {item.productName}
              </p>

              {isFreeTrialOffer && (
                <Badge
                  isSmall={true}
                  label={offer.offerDescription}
                  className={styles.offerBadgeTreeTrial}
                  bgColor={'#51AFD5'}
                />
              )}

              {(!item.rowTotal || !item.frequencyDays) && (
                <p data-testid="basket-item-description" className={styles.itemDesc}>
                  One time purchase
                </p>
              )}

              {item.offersApplied.length > 0 && !isFreeTrialOffer && (
                <BasketItemOfferBadge offer={offer} item={item} currency={currency} />
              )}

              {item.rowTotal && item.frequencyDays && (
                <p data-testid="basket-item-description" className={styles.itemDesc}>
                  {itemDescription}
                </p>
              )}

              {isRestricted && (
                <div className={styles.warning}>
                  This item is unavailable for delivery {country ? `to ${country}` : ''}
                </div>
              )}

              <div
                className={`${styles.rowBasketDetailsNoMargin} ${
                  item.allowQuantityChange ? '' : styles.noButtons
                }`}
              >
                <div className={styles.basketItemQuantity}>
                  {item.allowQuantityChange ? (
                    isMobile ? (
                      <Select
                        defaultValue={{ label: String(item.qty), value: item.qty }}
                        onSelect={handleQuantityChange}
                        options={ITEM_QUANTITY_OPTIONS}
                      />
                    ) : (
                      <Dropdown
                        loading={isLoading}
                        dataQa={`basket-quantity-${item.productName}`}
                        defaultValue={{ label: String(item.qty), value: item.qty }}
                        onSelect={handleQuantityChange}
                        options={ITEM_QUANTITY_OPTIONS}
                      />
                    )
                  ) : (
                    <div className={styles.itemQuantity}>x{item.qty}</div>
                  )}
                </div>
                <div className={styles.itemTotal}>
                  {itemHasDiscount ? (
                    <>
                      {!isFreeTrialOffer && (
                        <s className={styles.prevPrice} data-testid="basket-item-prev-price">
                          {itemRowTotalPrice}
                        </s>
                      )}
                      <span className={styles.price} data-testid="basket-item-offer-price">
                        {isFreeTrialOffer ? 'FREE' : itemRowTotalPriceWithDiscount}
                      </span>
                    </>
                  ) : (
                    <span
                      className={styles.price}
                      data-testid={`basket-item-price-${item.productName}`}
                    >
                      {itemRowTotalPrice}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {hasSubscriptionOffer && (
        <div className={hasMultiMonthOffer ? styles.itemMultiMonth : styles.itemSubscription}>
          <div className={styles.textSubscribe}>{`
            Switch to ${
              hasMultiMonthOffer ? 'multi-month' : 'subscription'
            }  & save ${upsellSavePercentage}%
          `}</div>
          <button className={styles.buttonSubscribe} onClick={handleAddUpsellItem}>
            Subscribe
          </button>
        </div>
      )}
    </li>
  );
};

export default BasketItem;
