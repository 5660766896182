import { useQuery } from '@apollo/react-hooks';
import { WatchQueryFetchPolicy } from 'apollo-boost';
import { COOKIES } from '@constants/cookies';
import { BASKET_ITEM_COUNT, GET_BASKET } from '@queries/basket';
import { getCookie } from '@services/cookies';
import { client } from '../apollo/client';
import { IBasketItemCountResponse, IBasketQueryResponse } from '../types/query-response';
import { DataProxy } from 'apollo-cache';
import { IBasket } from '../types/basket';

const updateCachedBasketCount = async (itemCount: number) => {
  const { data } = await client.query({
    query: BASKET_ITEM_COUNT,
    variables: { basketId: getCookie(COOKIES.basketId) },
  });

  if (data) {
    client.writeQuery({
      query: BASKET_ITEM_COUNT,
      data: {
        basket_itemCount: itemCount,
      },
      variables: {
        basketId: getCookie(COOKIES.basketId),
      },
    });
  }
};

const useBasketItemCount = () => {
  return useQuery<IBasketItemCountResponse>(BASKET_ITEM_COUNT, {
    fetchPolicy: 'network-only',
    variables: { basketId: getCookie(COOKIES.basketId) },
  });
};

const useBasket = (
  fetchPolicy: WatchQueryFetchPolicy = 'network-only',
  onCompletedCallback?: () => void
) => {
  const basketId = getCookie(COOKIES.basketId);

  const { data, error, loading, refetch } = useQuery<IBasketQueryResponse>(GET_BASKET, {
    fetchPolicy,
    variables: { basketId },
    onCompleted: res => {
      const itemCount = res?.basket_getBasket?.itemCount;
      itemCount && updateCachedBasketCount(itemCount);
      onCompletedCallback && onCompletedCallback();
    },
  });

  return {
    data: data?.basket_getBasket,
    error,
    loading,
    refetch,
  };
};

const useCachedBasket = (cache: DataProxy) => {
  const data =
    cache.readQuery<IBasketQueryResponse>({
      query: GET_BASKET,
      variables: {
        basketId: getCookie(COOKIES.basketId),
      },
    }) || ({} as IBasketQueryResponse);
  return data;
};

const updateCachedBasket = (basketData: IBasket | null) => {
  client.writeQuery({
    query: GET_BASKET,
    data: { basket_getBasket: basketData },
    variables: {
      basketId: getCookie(COOKIES.basketId),
    },
  });

  const itemCount = basketData?.itemCount;
  updateCachedBasketCount(itemCount || 0);
};

export {
  useBasket,
  useCachedBasket,
  updateCachedBasket,
  useBasketItemCount,
  updateCachedBasketCount,
};
