import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import { QueryFunctionOptions } from 'react-apollo';

import * as Sentry from '@sentry/gatsby';
// queries
import { USER_DETAILS, USER_KIT_STATUS } from '@queries/user';
// context
import { useContext } from 'react';
import { GlobalContext } from '@store/global-state';
// services
import { removeCookie } from '@services/cookies';
import { removeToken } from '@services/auth';
// constants
import { COOKIES } from '@constants/cookies';
// types
import { IUserDetailsQueryResponse } from '@src/types/query-response';
import { trackIdentify, trackTikTokUserDetails } from '@src/services/tracking/tracking';
import { DURATION_10_MINUTES } from '@src/constants/time';
import { setUserDataToGtag } from '@src/services/tracking/ga';

const errorUserDetailsHandler = (error: ApolloError) => {
  removeToken();
  removeCookie(COOKIES.basketId);
  Sentry.captureException(error);
};

export const useGetUserDetailsLazyQuery = (options?: QueryFunctionOptions) => {
  const { setIsLoggedIn } = useContext(GlobalContext);
  return useLazyQuery<IUserDetailsQueryResponse>(USER_DETAILS, {
    ...options,
    onError(error) {
      setIsLoggedIn(false);
      errorUserDetailsHandler(error);
    },
  });
};

export const useGetUserDetailsQuery = (options?: QueryFunctionOptions) => {
  const { setIsLoggedIn } = useContext(GlobalContext);
  return useQuery<IUserDetailsQueryResponse>(USER_DETAILS, {
    ...options,
    onError(error) {
      setIsLoggedIn(false);
      errorUserDetailsHandler(error);
    },
    pollInterval: DURATION_10_MINUTES,
  });
};

export const useGetUserDetails = () => {
  const { data, loading } = useGetUserDetailsQuery();
  const user = data?.user_details;

  if (user) {
    trackIdentify(
      user.customerDataPlatformId,
      {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      },
      {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      }
    );
    trackTikTokUserDetails(user.email, user.phone);
    setUserDataToGtag({
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
    });
  }

  return {
    user,
    loading,
  };
};

export const useGetUserKitDetails = () => {
  const { data, loading, error } = useQuery(USER_KIT_STATUS);

  return {
    data: data?.user_kitsStatus,
    loading,
    error,
  };
};
