import React from 'react';
import { Image as ImageUI } from 'vitl-component-library';

import { DEFAULT_IMAGE_WIDTH } from './constants';

interface IProps {
  width?: number;
  maxWidth?: number;
  filename?: string;
  filenameTablet?: string; // If the tablet image is different one
  filenameMobile?: string; // If the mobile image is different one
  src?: string; // When want to use whole image url
  alt?: string;
  height?: number;
  className?: string;
  hideOnDesktop?: boolean;
  hideOnTablet?: boolean;
  hideOnMobile?: boolean;
  hideBiggerThenMobile?: boolean;
  noBlurLoading?: boolean; // When we dont want to have this blurry image first
}

const Image = ({
  filename,
  filenameTablet,
  filenameMobile,
  src,
  width = DEFAULT_IMAGE_WIDTH,
  maxWidth,
  height,
  alt,
  className = '',
  hideOnDesktop,
  hideOnTablet,
  hideOnMobile,
  hideBiggerThenMobile,
  noBlurLoading,
}: IProps) => {
  return (
    <ImageUI
      mediaUrl={process.env.GATSBY_MEDIA_URL}
      filename={filename}
      width={width}
      maxWidth={maxWidth}
      filenameTablet={filenameTablet}
      filenameMobile={filenameMobile}
      url={src}
      height={height}
      alt={alt}
      className={className}
      hideOnDesktop={hideOnDesktop}
      hideOnTablet={hideOnTablet}
      hideOnMobile={hideOnMobile}
      hideBiggerThenMobile={hideBiggerThenMobile}
      noBlurLoading={noBlurLoading}
    />
  );
};

export default Image;
