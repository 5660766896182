import React, { useContext, useEffect } from 'react';
import { client } from '@src/apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { CSSTransition } from 'react-transition-group';
// queries
import { CROSS_SELL_BASKET_PRODUCTS_MARKETING } from '../../queries/product';
import { getCookie } from '../../services/cookies';
// context
import { GlobalContext } from '@store/global-state';
// components
import Modal from '../modal';
import ModalBody from '../modal/modal-body';
import Loader from '../loader';
import { MODAL_TYPE } from '@components/modal/constants';
import SubmitButton from '@components/submit-button';

// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
import { COOKIES } from '@constants/cookies';
import { updateCachedBasket, useCachedBasket } from '@hooks/useBasket';
// styles
import styles from './modal-cross-sell-single.module.scss';
import fadeTransition from '../../styles/fade.module.scss';
import {
  getEventId,
  trackEvent,
  trackFacebook,
  trackPinterest,
  trackReddit,
  trackTiktok,
} from '@services/tracking/tracking';
//types
import { ICrossSellBasketProductsQueryResponse } from '@src/types/query-response';
import { useAddToBasket } from '@src/features/shop/hooks/useAddToBasket';
import { IBasket } from '@src/types/basket';

const ModalCrossSellSingle = () => {
  const { showCrossSellSingle, setShowCrossSellSingle } = useContext(GlobalContext);

  // queries
  const { data, loading } = useQuery<ICrossSellBasketProductsQueryResponse>(
    CROSS_SELL_BASKET_PRODUCTS_MARKETING,
    {
      fetchPolicy: 'network-only',
    }
  );

  const crossSellItem = data?.content_crossSellBasket.popup[0];
  const { dataPlans, price, offerPrice } = useGetProductPlansData(
    crossSellItem?.configurableSku || '',
    null,
    'oneOff'
  );

  const onCompleteAddToBasket = (data: IBasket) => {
    const basketData = useCachedBasket(client);
    basketData.basket_getBasket = data;
    updateCachedBasket(basketData.basket_getBasket);
    showCrossSellSingle && setShowCrossSellSingle(false);
  };
  const onErrorAddToBasket = () => {
    setShowCrossSellSingle(false);
  };
  const { addItemToBasket, loading: loadingAddItemToBasket } = useAddToBasket(
    onCompleteAddToBasket,
    onErrorAddToBasket,
    'basket_popup_add'
  );

  const getSku = () => {
    if (!dataPlans?.product_plans) return null;
    if (dataPlans.product_plans.length === 1) return dataPlans.product_plans[0].sku;
    return dataPlans.product_plans[1].sku;
  };

  const handleAddItemToBasket = () => {
    addItemToBasket({
      variables: {
        basketId: getCookie(COOKIES.basketId),
        sku: getSku(),
        quantity: 1,
      },
    });
  };

  const handleModalClose = () => {
    setShowCrossSellSingle(false);
  };

  useEffect(() => {
    if (!showCrossSellSingle) {
      return;
    }

    const eventName = 'basket_popup';
    const eventId = getEventId();

    trackReddit(eventName);
    trackFacebook(eventName, undefined, eventId);
    trackTiktok(eventName, undefined, eventId);
    trackPinterest(eventName);
    trackEvent(eventName, undefined, undefined, eventId);
  }, [showCrossSellSingle]);

  useEffect(() => {}, [showCrossSellSingle]);

  if (loading) return <Loader />;

  if (!crossSellItem) {
    setShowCrossSellSingle(false);
    return null;
  }

  return (
    <Modal type={MODAL_TYPE.crossSellSingle} handleClose={handleModalClose}>
      <ModalBody type={MODAL_TYPE.crossSellSingle}>
        <div className={styles.productDetails}>
          <img
            src={`${process.env.GATSBY_DIRECTUS_MARKETING_MEDIA_URL}/${crossSellItem.imageUrl}`}
            alt=""
          />
          <div className={styles.planDetails}>
            <h3 className={styles.h3}>{crossSellItem.name}</h3>
            <p>
              {offerPrice ? <s>{price} </s> : null}
              <strong>{offerPrice || price}</strong>
            </p>
          </div>
        </div>

        <div
          className={styles.itemDesc}
          dangerouslySetInnerHTML={{
            __html: crossSellItem.content,
          }}
        />

        <SubmitButton
          onClick={() => handleAddItemToBasket()}
          loading={loadingAddItemToBasket}
          buttonLabel={crossSellItem.cta}
        />
      </ModalBody>
    </Modal>
  );
};

export default ModalCrossSellSingle;
