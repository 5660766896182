import React, { useContext } from 'react';
import Modal from '../modal';
// context
import { GlobalContext } from '@store/global-state';
// components
import ModalHeader from '../modal/modal-header';
import ModalBody from '../modal/modal-body';
import { MODAL_TYPE } from '@components/modal/constants';
// styles
import fadeTransition from '../../styles/fade.module.scss';
import { CSSTransition } from 'react-transition-group';
import styles from './modal-message.module.scss';

const ModalMessage: React.FC = () => {
  const { messageModal, setMessageModal } = useContext(GlobalContext);

  return (
    <CSSTransition
      in={messageModal.visible}
      timeout={300}
      classNames={{ ...fadeTransition }}
      onExited={() =>
        setMessageModal({
          ...messageModal,
          title: '',
          message: '',
          buttonLabel: '',
        })
      }
      unmountOnExit
    >
      <Modal
        type={MODAL_TYPE.message}
        handleClose={() => {
          setMessageModal({ ...messageModal, visible: false });
        }}
      >
        <ModalHeader type={MODAL_TYPE.message}>
          {messageModal.iconSrc && <img width="20px" src={messageModal.iconSrc} alt="icon-modal" />}
          {messageModal.message ? <h2>{messageModal.title}</h2> : <h3>{messageModal.title}</h3>}
        </ModalHeader>
        <ModalBody type={MODAL_TYPE.message}>
          {messageModal.message && <p className={styles.messageText}>{messageModal.message}</p>}
          <button
            className={styles.buttonPrimary}
            onClick={() => {
              setMessageModal({ visible: false });
            }}
          >
            {messageModal.buttonLabel}
          </button>
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default ModalMessage;
