import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useBasket } from './useBasket';
import { STORE_COUNTRIES } from '@queries/delivery';
import { BASKET_CHECK_PRODUCT_RESTRICTIONS } from '@queries/basket';
import { getCountryNameById } from '@services/global';
import { getCookie } from '@services/cookies';
import { COOKIES } from '@constants/cookies';

const useRestrictedItems = () => {
  const { data } = useBasket();

  const basketId = getCookie(COOKIES.basketId);

  const [checkProductRestrictions, { data: dataProductRestrictions }] = useLazyQuery(
    BASKET_CHECK_PRODUCT_RESTRICTIONS,
    {
      fetchPolicy: 'no-cache',
      variables: { basketId },
    }
  );

  const { data: countriesData } = useQuery(STORE_COUNTRIES, {
    fetchPolicy: 'cache-and-network',
  });

  const countries = countriesData?.store_countries;

  const restricted =
    dataProductRestrictions?.basket_checkProductRestrictions?.hasRestrictedItems &&
    dataProductRestrictions?.basket_checkProductRestrictions?.restrictedItems;

  const country = getCountryNameById(countries, data?.deliveryAddress?.countryId || '');

  return { restricted, countries, country, checkProductRestrictions };
};

export default useRestrictedItems;
