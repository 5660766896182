import { graphql, useStaticQuery } from 'gatsby';

export const useLayoutData = () => {
  const {
    allDirectusGenericPage,
    allDirectusModal,
    directusFeatureFlag,
    directusSetting,
    site,
  } = useStaticQuery(graphql`
    query SocialLinks {
      site {
        siteMetadata {
          title
        }
      }
      directusFeatureFlag {
        flags
      }
      directusSetting {
        logo {
          title
          filename_disk
        }
        logo_primary {
          title
          filename_disk
        }
        navigation_links {
          label
          path
        }
        navigation_links_2 {
          label
          path
        }
        social_links_label
        social_links {
          icon
          url
        }
        sign_up_label
        sign_up_placeholder
        cookies_description
        cookies_button_label
      }
      allDirectusGenericPage(filter: { show_in_navigation: { eq: "footer" } }) {
        edges {
          node {
            path
            title
          }
        }
      }
      allDirectusModal {
        nodes {
          type
          redirect_path
          image {
            filename_disk
          }
        }
      }
    }
  `);
  return {
    allDirectusGenericPage,
    allDirectusModal,
    directusFeatureFlag,
    directusSetting,
    site,
  };
};
