export const FILTER_ANIMATION = {
  closed: {
    paddingTop: '0px',
    height: '0px',
    opacity: 0,
    transition: { type: 'linear', bounce: 0, duration: 0.2 },
  },
  open: {
    paddingTop: '24px',
    height: 'auto',
    opacity: 1,
    transition: { type: 'spring', bounce: 0, duration: 0.2 },
  },
};
