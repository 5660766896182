import React, { useEffect, useRef, useState } from 'react';
import useClickedOutside from '@hooks/useClickedOutside';
import styles from './dropdown.module.scss';
import { v4 as uuidv4 } from 'uuid';

export interface IOption {
  value: number;
  label: string;
}

interface IProps {
  options: IOption[];
  onSelect: (option: IOption) => void;
  loading?: boolean;
  defaultValue?: IOption;
  dataQa?: string;
}

const Dropdown: React.FC<IProps> = ({
  options,
  onSelect,
  loading,
  defaultValue,
  dataQa,
}: IProps) => {
  const elementRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IOption>(defaultValue || options[0]);

  const handleSelectOption = (option: IOption) => {
    if (option.label !== 'Remove') {
      setSelectedOption(option);
    }
    onSelect && onSelect(option);
  };

  const outsideElementClicked = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };

  useClickedOutside(elementRef, outsideElementClicked);

  useEffect(() => {
    loading && setIsDropdownOpen(false);
  }, [loading]);

  return (
    <div ref={elementRef} className={isDropdownOpen ? styles.dropdownOpen : styles.dropdown}>
      <div
        data-testid={dataQa}
        className={styles.dropdownToggle}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {selectedOption.label}
        <img className={styles.chevron} src={'/images/icon_chevron.svg'} alt="icon chevron" />
      </div>

      <ul className={styles.dropdownItems}>
        {options.map((option, index: number) => (
          <li
            key={uuidv4()}
            className={
              selectedOption.value === option.value
                ? styles.dropdownItemSelected
                : styles.dropdownItem
            }
            onClick={() => handleSelectOption(option)}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
