export const MODAL_TYPE = {
  fullHeight: 'FullHeight',
  crossSell: 'CrossSell',
  manageGoals: 'ManageGoals',
  message: 'Message',
  crossSellSingle: 'CrossSellSingle',
  nutrients: 'Nutrients',
  allNutrients: 'AllNutrients',
  discover: 'Discover',
  confirm: 'Confirm',
};
