import { useEffect, RefObject } from 'react';

const useClickedOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callback: (event: any) => void
): void => {
  function handleClickOutside(event: any) {
    if (ref?.current && !ref.current.contains(event.target)) {
      callback(event);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export default useClickedOutside;
