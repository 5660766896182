import { useProductPlansNewQuery } from '@hooks/useProductPlans';
import { formatPrice } from '@services/format';

/**
 * Fetches product plans data based on the provided identifier and/or product SKU and cache the response.
 * @param {string} [identifier] - is `personalised` for example and it will give us all personalised plans
 * @param {string} [productSku] - can be uset to get us a specific plan form all plans which identifier gives us.
 * `sn25` for example can be use to get the plan for the product with sku `sn25` from all personalised plans
 * @example
 * useGetProductPlansData('personalised', 'sn25');
 */

const useGetProductPlansData = (
  identifier?: string,
  productSku?: string | null,
  type?: 'oneOff' | 'subscription'
) => {
  const { data: plans, loading } = useProductPlansNewQuery({});

  const currentPlans = plans?.product_plans.filter(plan => plan.parentSku === identifier);

  const currentPlansFiltered = type
    ? currentPlans?.filter(plan => {
        return type === 'oneOff' ? !plan.deliveryFrequency : plan.deliveryFrequency;
      })
    : currentPlans;

  const currentProductPlans = productSku
    ? currentPlansFiltered?.filter(plan => plan.sku.includes(productSku))
    : currentPlansFiltered;
  // reduce function will find the minimal price from all the plans for the product
  const fromPrice = currentProductPlans
    ? currentProductPlans.reduce((minPrice, plan) => {
        const price = plan.offerPrice || plan.rrpPrice;
        return minPrice && minPrice < price ? minPrice : price;
      }, 0)
    : null;
  const currentPlanFormated = { product_plans: currentProductPlans };

  const isAvailable =
    currentProductPlans &&
    currentProductPlans.length > 0 &&
    currentProductPlans.some(plan => plan.purchasable);
  const hasSubscriptionPlan =
    currentProductPlans && currentProductPlans.some(plan => plan.deliveryFrequency);
  const price = isAvailable
    ? formatPrice(currentProductPlans[0]?.rrpPrice, currentProductPlans[0]?.currency)
    : '';
  const offerPrice =
    isAvailable && currentProductPlans[0]?.offerPrice
      ? formatPrice(currentProductPlans[0]?.offerPrice, currentProductPlans[0]?.currency)
      : '';

  return {
    price,
    offerPrice,
    fromPrice: fromPrice ? formatPrice(fromPrice) : '',
    hasSubscriptionPlan: !!hasSubscriptionPlan,
    dataPlans: currentPlanFormated,
    loading,
    isAvailable,
    allPlans: plans,
  };
};

export default useGetProductPlansData;
