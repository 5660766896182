import React, { useContext } from 'react';

// context
import { GlobalContext } from '@store/global-state';
// components
import SearchResult from '@components/search/search-result';
// hooks
import { useSearch } from '@components/search/useSearch';
// constants
import { SIDEBAR_VIEWS } from '@components/sidebar/constants';
// styles
import styles from './search.module.scss';

const SearchContainer = () => {
  const { filteredProducts, filterProducts, redirectToCbd } = useSearch();
  const { searchValue, setSearchValue, setSidebar } = useContext(GlobalContext);

  const handleSearchInput = (value: string) => {
    setSearchValue(value);
    filterProducts(value);
  };

  const onSearhClick = () => {
    if (searchValue) setSearchValue('');
  };

  const onSearchClick = () => setSidebar(SIDEBAR_VIEWS.empty);

  return (
    <>
      <div className={styles.inputWrap}>
        <input
          id={'search-input'}
          className={styles.searchInput}
          data-testid={'search-input'}
          placeholder={'Search products'}
          onInput={e => handleSearchInput(e.target.value)}
          onKeyPress={e => (e.key === 'Enter' ? redirectToCbd(e.target.value) : null)}
          value={searchValue}
        />
        <img
          src={`${searchValue ? '/images/icon_close.svg' : '/images/icon-search.svg'}`}
          onClick={onSearhClick}
          alt="Search Icon"
        />
      </div>
      <SearchResult
        searchValue={searchValue}
        filteredProducts={filteredProducts}
        callback={onSearchClick}
      />
    </>
  );
};

const SidebarSearch = () => {
  return (
    <div className={styles.headerSearch}>
      <SearchContainer />
    </div>
  );
};

export default SidebarSearch;
