import { useState } from 'react';
import products from './products.json';
import { IUseSearchReturn } from '@interfaces/global';

export const useSearch = () => {
  const [filteredProducts, setFilteredProducts] = useState<Array<{}>>([]);

  const redirectToCbd = (value: string) => {
    if (value.toLowerCase() === 'cbd') {
      window.location.href = 'http://vitl-cbd.com';
    }
  };

  const filterProducts = (value: string) => {
    if (value === '') {
      setFilteredProducts([]);
      return;
    }
    setFilteredProducts(
      products.filter((product: any) => {
        return product.keywords.some((keyword: string) => keyword.toLowerCase().includes(value.toLowerCase()));
      })
    );
  };

  return { filteredProducts, filterProducts, redirectToCbd } as IUseSearchReturn;
};
