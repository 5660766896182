import { useContext, useEffect } from 'react';
import { useLocation } from '@reach/router';
// services
import { useScript } from '@services/useHooks';
import { getCookie, setCookie } from '@services/cookies';
import { setSessionStorageItem } from '../utils/storage';
//context
import { GlobalContext } from '@store/global-state';
//constants
import { AWIN_URL } from '@constants/variables';
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';
import { STORAGE_KEYS } from '@constants/storage';
//hooks
import { useLayoutData } from '@hooks/directusHooks/useLayoutData';
import { useCreateGuest } from '@hooks/useCreateGuest';
import { useFeatureFlags } from './useFeatureFlags';
import { useDetectLocale } from './useDetectLocale';
import { useContentBanner } from './useContentBanner';
import { useCheckLogInAsync } from './useCheckLogInUser';
import { useAddToBasket } from '@src/features/shop/hooks/useAddToBasket';
import { useAddCoupon } from './useAddCoupon';
import couponCodeService from '@src/utils/couponCodeService';

//To do

const useRouteActions = () => {
  const { setShowBasket } = useContext(GlobalContext);

  const onCompleteAddItemToCart = () => {
    setShowBasket(true);
  };
  const { addItemToBasket } = useAddToBasket(onCompleteAddItemToCart);
  const { addCoupon } = useAddCoupon({});
  const route = useLocation().search;

  //functions
  const triggerRouteActions = () => {
    let params = new URLSearchParams(route);

    const basketId = getCookie(COOKIES.basketId);
    const isCurrencySet = getCookie(COOKIES.currency);
    const isUserCountrySet = getCookie(COOKIES.userCountry);

    //Workaround to make openBasket work with two question marks routes
    const hasRouteOpenBasket = route.includes('openBasket');

    if (hasRouteOpenBasket) {
      const hasRouteTwoQuestionMarks = route.split('?')[2];

      // Save the utm param in the cookies
      if (hasRouteTwoQuestionMarks) {
        const [utmKey, utmValue] = route.split('?')[2]?.split('&')[0]?.split('=');

        setCookie(utmKey, utmValue, COOKIE_EXPIRES_IN.maxAge);

        if (!isCurrencySet || !isUserCountrySet) {
          setCookie(COOKIES.currency, 'GBP', COOKIE_EXPIRES_IN.thirtyDays);
          setCookie(COOKIES.userCountry, 'GB', COOKIE_EXPIRES_IN.thirtyDays);
        }
      }

      params = new URLSearchParams(route.replace(/\?/g, '&'));
    }

    const routesActions = {
      currency: () => {
        const currency = String(params.get('currency')?.toUpperCase());
        setCookie(COOKIES.currency, currency, COOKIE_EXPIRES_IN.thirtyDays);
      },
      addPlanToCart: () =>
        addItemToBasket({
          variables: {
            basketId,
            sku: params.get('addPlanToCart'),
            quantity: 1,
          },
        }),
      claimCoupon: () => {
        const { setCoupon } = couponCodeService();
        const couponCode = params.get('claimCoupon');

        if (couponCode) {
          setCoupon(couponCode); //! Check if needed to save it here
          addCoupon({
            variables: {
              basketId,
              couponCode,
            },
          });
        }
      },
      openBasket: () => {
        const param = params.get('openBasket');

        if (param?.includes('cart'))
          setCookie(COOKIES.basketId, param, COOKIE_EXPIRES_IN.thirtyDays);
        setShowBasket(true);
      },
    };

    for (const [key, value] of Object.entries(routesActions)) {
      if (params.has(key)) value();
    }
  };

  return { triggerRouteActions };
};

const useUpdateAppHeight = () => {
  const { setAppHeight } = useContext(GlobalContext);

  const updateAppHeight = () => {
    setAppHeight(window.innerHeight);
  };

  const addHeightResizeListener = () => {
    window.addEventListener('resize', updateAppHeight);
  };

  const removeHeightResizeListener = () => {
    window.removeEventListener('resize', updateAppHeight);
  };

  return {
    updateAppHeight,
    addHeightResizeListener,
    removeHeightResizeListener,
  };
};

const useUtmModal = () => {
  //context
  const { setUtmModalData } = useContext(GlobalContext);
  //hooks
  const { allDirectusModal } = useLayoutData();

  const setUtmModal = () => {
    // check for UTM modal stored in cookies
    const modalType = getCookie(COOKIES.utm_show_popup);
    const modalData = allDirectusModal.nodes.find((modal: any) => modal.type === modalType);
    const isModalSeen = getCookie('utm-modal-' + modalType);
    if (!isModalSeen && modalData) {
      setUtmModalData(modalData);
    }
  };
  return { setUtmModal };
};

export const useLayout = () => {
  const { allDirectusGenericPage, directusSetting, site } = useLayoutData();
  const {
    bannerData,
    bannerHeight,
    showCrossSellSingle,
    messageModal,
    utmModalData,
    showBasket,
    showSearch,
    setShowSearch,
    setShowBasket,
    sidebar,
    showShopModal,
    isCheckLoginTried,
  } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const params = new URLSearchParams(useLocation().search);
  const hasTokenInParams = params.get('token');
  const traceTokenParamValue = params.get('traceToken');

  //hooks
  const { setUtmModal } = useUtmModal();
  const { triggerRouteActions } = useRouteActions();
  const { startGuestCreate, loading: loadingCreateGuest } = useCreateGuest();
  const { getFeatureFlags, loadingFeatureFlags, dataFeatureFlags } = useFeatureFlags();
  const { detectLocale } = useDetectLocale();
  const { getContentBanner, loading: loadingBannerContent } = useContentBanner();
  const { checkLogInUser, checkingLogin, setCheckingLogin } = useCheckLogInAsync();

  const {
    updateAppHeight,
    addHeightResizeListener,
    removeHeightResizeListener,
  } = useUpdateAppHeight();

  useScript(`${AWIN_URL}`); // init AWIN script

  useEffect(() => {
    getContentBanner();
  }, [pathname]);

  useEffect(() => {
    if (!isCheckLoginTried)
      checkLogInUser();
    else
      setCheckingLogin(false);

    !dataFeatureFlags && getFeatureFlags();
    updateAppHeight();
    addHeightResizeListener();
    !hasTokenInParams && startGuestCreate();
    traceTokenParamValue && setSessionStorageItem(STORAGE_KEYS.traceToken, traceTokenParamValue);
    setUtmModal();

    const isUserCountrySet = getCookie(COOKIES.userCountry);
    const isUserCurrencySet = getCookie(COOKIES.currency);

    if (!isUserCountrySet || !isUserCurrencySet) detectLocale();
    return () => removeHeightResizeListener();
  }, [isCheckLoginTried]);

  useEffect(() => {
    triggerRouteActions();
  }, []);

  const loading = loadingCreateGuest || loadingFeatureFlags || checkingLogin;
  return {
    loading,
    loadingBannerContent,
    bannerData,
    bannerHeight,
    site,
    directusSetting,
    allDirectusGenericPage,
    showCrossSellSingle,
    messageModal,
    utmModalData,
    showBasket,
    setShowBasket,
    showSearch,
    setShowSearch,
    sidebar,
    showShopModal,
  };
};
