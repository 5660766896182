import React from 'react';
import Error from '../error/error';
import styles from './submit-button.module.scss';

interface IProps {
  loading?: boolean;
  success?: any;
  error?: any;
  buttonLabel?: string;
  errorMessage?: string;
  successMessage?: string;
  fullWidth?: boolean;
  onClick?: any;
  disabled?: any;
  className?: any;
}

const SubmitButton: React.FC<IProps> = ({
  loading,
  success,
  error,
  buttonLabel = 'Submit',
  errorMessage = 'There was an error.',
  successMessage = '',
  fullWidth,
  onClick,
  disabled,
  className = '',
}) => {
  return (
    <div className={className}>
      {!loading && success && successMessage !== '' && <p className={styles.success}>{successMessage}</p>}

      {!loading && <Error data={error} noMargin />}

      {loading && (
        <button
          type={onClick ? 'button' : 'submit'}
          className={fullWidth ? styles.buttonFullWidth : styles.button}
          disabled={loading || disabled}
          onClick={onClick}
        >
          Loading... <div className={styles.loader}></div>
        </button>
      )}

      {!loading && (
        <button
          type={onClick ? 'button' : 'submit'}
          className={fullWidth ? styles.buttonFullWidth : styles.button}
          disabled={loading || disabled}
          onClick={onClick}
        >
          {buttonLabel}
        </button>
      )}
    </div>
  );
};

export default SubmitButton;
