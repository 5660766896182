import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { trackPageView } from '@services/tracking/tracking';
import { GlobalContext } from '@store/global-state';
import { getCookie } from '@src/services/cookies';
import { COOKIES } from '@src/constants/cookies';
import VWOSmartCode from '@services/tracking/VWOSmartCode';

interface IProps {
  title: string;
  description: string;
  image?: string;
  noindex?: boolean;
}

const SEO = ({ title, description, image, noindex }: IProps) => {
  const { showBasket, showShopModal } = useContext(GlobalContext);
  const isUserCountrySet = getCookie(COOKIES.userCountry);
  const language = isUserCountrySet ? `en-${isUserCountrySet}` : 'en';

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
          }
        }
      }
    `
  );

  const { defaultTitle, defaultDescription } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || '',
  };

  const robotsContent = `max-snippet:-1, max-image-preview:large, max-video-preview:-1${
    noindex ? ', noindex' : ''
  }`;

  useEffect(() => {
    trackPageView(title);
  }, []);

  const helmetMeta = [
    {
      name: `description`,
      content: seo.description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: seo.description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: seo.description,
    },
    {
      name: `robots`,
      content: robotsContent,
    },
    {
      name: 'hrefLang',
      content: language,
    },
  ];

  if (seo.image) {
    helmetMeta.push({
      name: 'og:image',
      content: seo.image,
    });
  }

  const getBodyClass = () => {
    let className = '';
    showShopModal && className.concat('body-fixed');
    showBasket && className.concat('basket-open');
    return className;
  };

  return (
    <Helmet
      title={seo.title || 'siemka'}
      bodyAttributes={{
        class: getBodyClass(),
      }}
      htmlAttributes={{
        lang: 'en',
      }}
      meta={helmetMeta}
    >
      <VWOSmartCode />
    </Helmet>
  );
};

export default SEO;
