import React from 'react';
import styles from './custom-checkbox.module.scss';

interface IProps {
  id: string;
  label: any;
  name: string;
  register: any;
  disabled?: boolean;
  error?: any;
  errorMessage?: string;
}

const CustomCheckbox: React.FC<IProps> = ({ id, label, name, register, disabled = false, error, errorMessage }) => (
  <div>
    <label className={!disabled ? styles.container : styles.containerDisabled}>
      {label}
      <input key={id} id={id} value={id} type="checkbox" name={name} ref={register} disabled={disabled} />
      <span className={error ? styles.checkMarkError : styles.checkMark} />
    </label>
    {error && errorMessage && <p className={styles.error}>{errorMessage}</p>}
  </div>
);

export default CustomCheckbox;
