import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
// services
import { getCookie, setCookie } from '../../services/cookies';
// queries
// context
import { GlobalContext } from '@store/global-state';
// components
import Modal from '../modal';
import ModalHeader from '../modal/modal-header';
import ModalBody from '../modal/modal-body';
import SubmitButton from '../submit-button';
import CustomInput from '../form/custom-input';
// styles
import styles from './modal-add-coupon.module.scss';
import fadeTransition from '../../styles/fade.module.scss';
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';
import { useAddCoupon } from '@src/hooks/useAddCoupon';
import couponCodeService from '@src/utils/couponCodeService';

const ModalAddCoupon: React.FC = () => {
  const { showAddCoupon, setShowAddCoupon } = useContext(GlobalContext);
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    formState: { dirtyFields },
  } = useForm();

  const basketId = getCookie(COOKIES.basketId);

  const onAddCouponError = () => {
    setShowErrorMessage(true);
  };
  const { addCoupon, loading: loadingCoupon, error: errorCoupon } = useAddCoupon({
    showMessageModals: false,
    onErrorCallback: onAddCouponError,
  });

  const onSubmit = (formData: any) => {
    const { setCoupon } = couponCodeService();
    const couponCode = formData.couponCode;
    setCoupon(couponCode); //! Check if this is needed

    addCoupon({
      variables: {
        basketId,
        couponCode,
      },
    });
  };

  return (
    <CSSTransition
      in={showAddCoupon}
      timeout={300}
      classNames={{ ...fadeTransition }}
      onExited={() => {
        setCouponSuccess(false);
        setShowErrorMessage(false);
      }}
      unmountOnExit
    >
      <Modal
        handleClose={() => {
          setShowAddCoupon(false);
        }}
      >
        <ModalHeader>
          {couponSuccess ? <h2>Coupon added</h2> : <h2>Enter your promo code</h2>}
        </ModalHeader>
        <ModalBody>
          {couponSuccess ? (
            <div className={styles.successMessage}>
              <p>Please add items to the basket to continue</p>
              <button
                className={styles.buttonPrimary}
                onClick={() => {
                  setShowAddCoupon(false);
                }}
              >
                OK
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <fieldset disabled={loadingCoupon}>
                <CustomInput
                  id="couponCode"
                  register={register({ required: true })}
                  label="Promo code"
                  isDirty={dirtyFields.couponCode}
                  error={errors.couponCode}
                  errorMessage="Please enter promo code"
                />
              </fieldset>
              <SubmitButton
                loading={loadingCoupon}
                error={showErrorMessage && errorCoupon}
                buttonLabel="Submit"
                fullWidth
              />
            </form>
          )}
        </ModalBody>
      </Modal>
    </CSSTransition>
  );
};

export default ModalAddCoupon;
