import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { REGISTER_USER } from '@queries/user';
import { setToken } from '@services/auth';
import { getCookie } from '@services/cookies';
import {
  getUtmParamsMap,
  trackEvent,
  trackHeapUserDetails,
  trackIdentify,
  trackReddit,
  trackTikTokUserDetails,
} from '@services/tracking/tracking';
import { useMergeGuestBasket } from './useMergeGuestBasket';
import { COOKIES } from '@constants/cookies';
import { useCheckLogInAsync } from './useCheckLogInUser';

export const useRegisterUser = () => {
  const [loading, setLoading] = useState(false);
  const { checkLogInUser } = useCheckLogInAsync();
  const { mergeGuestBasket } = useMergeGuestBasket();

  const [registerUser] = useMutation(REGISTER_USER);

  const handleRegisterUser = async (name: string, email: string) => {
    setLoading(true);
    try {
      const registerRes: any = await registerUser({
        variables: {
          firstName: name,
          email: email,
          leadAttribution: getUtmParamsMap(),
        },
      });
      const token = registerRes?.data.user_register?.token;
      if (!token) return;

      setToken(registerRes.data.user_register.token);
      await checkLogInUser();
      trackIdentify(registerRes.data.user_register.customerDataPlatformId);
      trackHeapUserDetails(email);
      trackTikTokUserDetails(email);
      trackEvent('Session Information', { is_logged_in: true });
      trackReddit('SignUp', {
        currency: getCookie(COOKIES.currency),
      });

      const basketId = getCookie(COOKIES.basketId);
      const isGuestbasketId = basketId && basketId.toString().length === 32;

      if (isGuestbasketId) {
        await mergeGuestBasket({ variables: { basketId } });
      }
      setLoading(false);
      return;
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  return {
    handleRegisterUser,
    loading,
  };
};
