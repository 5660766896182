import { useCallback, useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/react-hooks';
import { useStripe } from '@stripe/react-stripe-js';
//services
import { getCookie, removeCookie } from '@services/cookies';
import { isEmpty } from '@services/global';
import { getUtmParamsMap, trackHeapEvent } from '@services/tracking/tracking';
import { trackGAEvent } from '@src/services/tracking/ga';
import { formatGraphQLError } from '@services/format';
//queries
import { PAYMENT_METHODS } from '@queries/payment';
import { GET_SHIPPING_ADDRESSES } from '@queries/delivery';
import {
  BASKET_APPLY_SHIPPING_ADDRESS,
  GET_BASKET,
  BASKET_REMOVE_PAYMENT_INTENT,
  BASKET_ORDER,
  BASKET_SET_PAYMENT_METHOD,
} from '@queries/basket';
//hooks
import { useRegisterUser } from './useRegisterUser';
//constants
import { GRAPH_QL_ERROR_TYPES } from '@constants/enums';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import { COOKIES } from '@constants/cookies';
//context
import { GlobalContext } from '@store/global-state';
// interfaces
import { IBasket } from '@src/types/basket';

const useEstimateShipping = (onComplete?: any) => {
  const [estimateShippingCosts, { loading, error }] = useMutation(BASKET_APPLY_SHIPPING_ADDRESS, {
    onCompleted: () => onComplete && onComplete(),
    onError: error => {
      throw error;
    },
  });
  return { estimateShippingCosts, loading, error };
};

const useApplyShippingAddress = (onComplete?: any) => {
  const [applyShippingAddress, { loading, error }] = useMutation(BASKET_APPLY_SHIPPING_ADDRESS, {
    update: (cache, response) => {
      const dataBasket: any = cache.readQuery({
        query: GET_BASKET,
        variables: { basketId: getCookie(COOKIES.basketId) },
      });
      const newDeliveryAddress = response?.data?.basket_applyShippingAddress?.deliveryAddress;

      const newBasketData = {
        ...dataBasket,
        basket_getBasket: {
          ...dataBasket.basket_getBasket,
          deliveryAddress: newDeliveryAddress,
        },
      };

      cache.writeQuery({
        query: GET_BASKET,
        variables: { basketId: getCookie(COOKIES.basketId) },
        data: newBasketData,
      });

      const userDeliveryAddressesData: any = cache.readQuery({
        query: GET_SHIPPING_ADDRESSES,
      });

      const newUserDeliveryAddressesData = {
        ...userDeliveryAddressesData,
        user_deliveryAddresses: userDeliveryAddressesData?.user_deliveryAddresses?.concat(
          newDeliveryAddress
        ),
      };

      cache.writeQuery({
        query: GET_SHIPPING_ADDRESSES,
        data: newUserDeliveryAddressesData,
      });
    },
    onCompleted: () => onComplete && onComplete(),
    onError: error => {
      throw error;
    },
  });
  return { applyShippingAddress, loading, error };
};

const useBasketSetPaymentMethod = (
  onComplete?: any,
  updateCachedPaymentMethods: boolean = false
) => {
  const [setBasketPaymentMethod, { loading, error }] = useMutation(BASKET_SET_PAYMENT_METHOD, {
    update: (cache, response) => {
      const dataBasket: any = cache.readQuery({
        query: GET_BASKET,
        variables: { basketId: getCookie(COOKIES.basketId) },
      });
      const newPayment = response?.data?.basket_setPaymentMethod;
      const newBasketData = {
        ...dataBasket,
        basket_getBasket: {
          ...dataBasket.basket_getBasket,
          payment: newPayment,
        },
      };

      cache.writeQuery({
        query: GET_BASKET,
        variables: { basketId: getCookie(COOKIES.basketId) },
        data: newBasketData,
      });

      if (updateCachedPaymentMethods) {
        const paymentMethodsData: any = cache.readQuery({
          query: PAYMENT_METHODS,
        });

        const newPaymentMethodsData = {
          ...paymentMethodsData,
          user_paymentMethods: paymentMethodsData.user_paymentMethods.concat(newPayment),
        };

        cache.writeQuery({
          query: PAYMENT_METHODS,
          data: newPaymentMethodsData,
        });
      }
    },
    onCompleted: () => {
      onComplete && onComplete();
    },
    onError: error => {
      throw error;
    },
  });
  return { setBasketPaymentMethod, loading, error };
};

const useOrder = (onComplete: any) => {
  const [orderBasket, { error, loading }] = useMutation(BASKET_ORDER, {
    variables: {
      basketId: getCookie(COOKIES.basketId),
      leadAttribution: getUtmParamsMap(),
    },
    onCompleted(res) {
      if (res.basket_order.id) {
        removeCookie(COOKIES.basketId);
        onComplete(res.basket_order.id);
      }
    },
    onError: error => {
      throw error;
    },
  });
  return { orderBasket, error, loading };
};

const useRemovePaymentIntent = () => {
  const [removePaymentIntent, { error, loading }] = useMutation(BASKET_REMOVE_PAYMENT_INTENT, {
    variables: {
      basketId: getCookie(COOKIES.basketId),
    },
  });
  return { removePaymentIntent, error, loading };
};

const useWallet = (basket: IBasket) => {
  //callbacks
  const onCompleteOrder = (orderId: string) => {
    navigate(`/checkout/confirmation?clearCart=true/&orderId=${orderId}`);
  };

  //hooks
  const stripe = useStripe();
  const { handleRegisterUser } = useRegisterUser();

  const { orderBasket } = useOrder(onCompleteOrder);
  const { applyShippingAddress } = useApplyShippingAddress();
  const { estimateShippingCosts } = useEstimateShipping();
  const { setBasketPaymentMethod } = useBasketSetPaymentMethod();
  const { removePaymentIntent } = useRemovePaymentIntent();

  //context
  const { setErrorModalMessage, isLoggedIn, featureFlags } = useContext(GlobalContext);

  //constants
  const basketDeliveryAddress = basket?.deliveryAddress;
  const basketData = basket || {};
  const basketPayment = basketData?.payment;
  const hasCartDeliveryAddress = basketDeliveryAddress?.street;

  //state
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultDeliveryAddress, setDefaultDeliveryAddress] = useState<any>(null);

  //functions
  const formatAddress = (address: any) => {
    const recipientArray = address.recipient?.split(/\s+/);
    //Gets everything except the last word as the first name.
    //Example: recipient: John Christopher Depp
    //First Name: John Christopher
    //Last Name: Deep
    const hasOnlyFirstName = recipientArray.length === 1;
    const lastName = hasOnlyFirstName ? '' : recipientArray.pop();
    const firstName = recipientArray.join(' ');
    //Joins the array of addresses in a string with line breaks
    const street = address.addressLine?.join('\r\n');

    return {
      ...address,
      firstName,
      lastName,
      street,
    };
  };

  const formatPaymentRequestTotals = (basket: any) => {
    return {
      total: {
        label: 'Total',
        amount: Math.floor(basket.totalWithCreditAllocated * 100),
      },
      shippingOptions: [
        {
          id: 'vitl',
          label: 'Vitl shipping method',
          detail: 'Standard',
          amount: basket.shipping * 100,
        },
      ],
      displayItems: [
        {
          amount: Math.floor(basket.subTotal * 100),
          label: `Subtotal`,
        },
        ...(basket.discountAmount
          ? [
              {
                amount: Math.floor(basket.discountAmount * 100),
                label: `Discount`,
              },
            ]
          : []),
        {
          amount: Math.floor(basket.shipping * 100),
          label: `Shipping`,
        },
      ],
    };
  };

  const handleSca = async (secret: string) => {
    setIsLoading(true);
    try {
      await stripe?.handleCardAction(secret);
      await orderBasket();
      setIsLoading(false);
      return;
    } catch (error) {
      const confirmPaymentData = await stripe?.confirmCardPayment(secret);
      setIsLoading(false);
      if (!confirmPaymentData?.error) {
        setIsLoading(true);
        await orderBasket();
        setIsLoading(false);
        return;
      }
      setErrorModalMessage(ERROR_MESSAGES.defaultSCA);
      removePaymentIntent();
    }
  };

  const updatePaymentRequest = (updatedBasket: any) => {
    if (!updatedBasket) return;
    paymentRequest?.update(formatPaymentRequestTotals(updatedBasket));
  };

  const estimateAndUpdateShippingCosts = async (
    shippingAddress: any,
    updateShippingCallback: any
  ) => {
    try {
      const estimatedShippingCostsData = await estimateShippingCosts({
        variables: {
          basketId: basketData?.basketId,
          firstName: shippingAddress.firstName,
          lastName: shippingAddress.lastName,
          street: shippingAddress.street,
          city: shippingAddress.city,
          postcode: shippingAddress.postalCode,
          countryId: shippingAddress.country,
        },
      });
      const estimatedShippingCosts = estimatedShippingCostsData?.data?.basket_applyShippingAddress;

      updateShippingCallback({
        ...formatPaymentRequestTotals(estimatedShippingCosts),
        status: 'success',
      });
    } catch (error) {
      updateShippingCallback({ status: 'fail' });
      return;
    }
  };

  const onSubmitWallet = async (paymentMethod: any, shippingAddress: any) => {
    // const { setErrorModalMessage } = useContext(GlobalContext);
    const paymentMethodId = paymentMethod.id;
    const hasShippingAddress = shippingAddress && shippingAddress.street;
    const basketPm = basketPayment?.id;
    const isPmMatchingBasket = paymentMethodId === basketPm;

    if (hasShippingAddress) {
      await applyShippingAddress({
        variables: {
          basketId: null,
          firstName: shippingAddress.firstName,
          lastName: shippingAddress.lastName,
          street: shippingAddress.street,
          city: shippingAddress.city,
          postcode: shippingAddress.postalCode,
          countryId: shippingAddress.country,
        },
      });
    }
    // else {
    //   setErrorModalMessage(ERROR_MESSAGES.missingShippingAddress);
    // }
    await setBasketPaymentMethod({
      variables: {
        basketId: null,
        methodId: paymentMethodId,
      },
    });
    if (!isPmMatchingBasket) await removePaymentIntent();
    await orderBasket();
  };

  const resetShippingAddress = async () => {
    if (!defaultDeliveryAddress) return;
    setIsLoading(true);
    await applyShippingAddress({
      variables: {
        basketId: basketData?.basketId,
        firstName: defaultDeliveryAddress.firstName,
        lastName: defaultDeliveryAddress.lastName,
        street: defaultDeliveryAddress.street,
        city: defaultDeliveryAddress.city,
        postcode: defaultDeliveryAddress.postcode,
        countryId: defaultDeliveryAddress.countryId,
      },
    });
    setIsLoading(false);
  };
  //listeners
  const addListeners = useCallback(() => {
    //Event triggered onSubmit
    paymentRequest?.on('paymentmethod', async (ev: any) => {
      const { payerName, payerEmail, paymentMethod, shippingAddress, complete } = ev;

      if(ev.walletName === 'applePay') {
        trackGAEvent('selected_applepay_basket', {});
        trackHeapEvent('selected_applepay_basket');
      } else if(ev.walletName === 'googlePay') {
        trackGAEvent('selected_googlepay_basket', {});
        trackHeapEvent('selected_googlepay_basket');
      }

      try {
        if (!isLoggedIn) {
          try {
            await handleRegisterUser(payerName, payerEmail);
          } catch (err) {
            const error = err?.graphQLErrors[0];
            const errorType = error.errorType as GRAPH_QL_ERROR_TYPES;
            const isAbortAvailable = Boolean(paymentRequest.abort);

            if (errorType === GRAPH_QL_ERROR_TYPES.EmailExists) {
              isAbortAvailable ? paymentRequest.abort() : complete('invalid_payer_email');
              setErrorModalMessage(ERROR_MESSAGES.emailExists);
              return;
            }

            complete('fail');
            setErrorModalMessage(formatGraphQLError(error?.message));
            return;
          }
        }
        await onSubmitWallet(paymentMethod, formatAddress(shippingAddress));
        if(ev.walletName === 'applePay') {
          trackGAEvent('order_completed_applepay_basket', {});
          trackHeapEvent('order_completed_applepay_basket');
        } else if(ev.walletName === 'googlePay') {
          trackGAEvent('order_completed_googlepay_basket', {});
          trackHeapEvent('order_completed_googlepay_basket');
        }
        complete('success');
      } catch (err) {
        complete('fail');

        const errorRequiresSca: any = err?.graphQLErrors.find(
          (error: any) => error.errorType === GRAPH_QL_ERROR_TYPES.PaymentRequiresSCA
        );

        if (errorRequiresSca) {
          const secret = errorRequiresSca.errorInfo?.secret;
          handleSca(secret);
        } else {
          setErrorModalMessage(formatGraphQLError(err?.graphQLErrors[0].message));
        }
      }
    });

    //Event triggered on shippingaddresschange and when Apple Pay modal is shown
    paymentRequest?.on('shippingaddresschange', (ev: any) => {
      const updateWith = ev.updateWith;
      const shippingAddress = formatAddress(ev.shippingAddress);

      if (!shippingAddress) {
        updateWith({ status: 'fail' });
        return;
      }
      estimateAndUpdateShippingCosts(shippingAddress, updateWith);
    });

    paymentRequest?.on('cancel', async () => resetShippingAddress());
  }, [paymentRequest]);

  const cleanListeners = () => {
    paymentRequest?.removeAllListeners();
  };

  //Saves the payment request if Apple Pay or Google Pay is available
  const savePrIfAvailable = useCallback(async () => {
    if (!stripe || !basketData || isEmpty(basketData)) return;
    setIsLoading(true);
    try {
      const pr: any = stripe.paymentRequest({
        ...formatPaymentRequestTotals(basketData),
        country: 'GB',
        currency: basketData.currency.toLowerCase(),
        requestPayerName: true,
        requestPayerEmail: !isLoggedIn,
        requestShipping: true,
      });
      const result = await pr.canMakePayment();
      
      const isApplePayAvailable = result?.applePay && featureFlags.apple;
      const isGooglePayAvailable = result?.googlePay && featureFlags.google;

      if (isApplePayAvailable || isGooglePayAvailable) setPaymentRequest(pr);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [stripe, hasCartDeliveryAddress]);

  useEffect(() => {
    //Address with an street populated will be saved as the default address one. Later on, it will be used onCancel apple pay modal to reset it back.
    const isDeliveryAddressPartial =
      !basketDeliveryAddress?.street || basketDeliveryAddress?.street == '-';

    if (!isDeliveryAddressPartial) {
      setDefaultDeliveryAddress(basketDeliveryAddress);
    }
  }, [basketDeliveryAddress]);

  useEffect(() => {
    const areFeatureFlagsActive = featureFlags?.apple || featureFlags?.google;

    if (!areFeatureFlagsActive) return;
    savePrIfAvailable();
  }, [savePrIfAvailable, featureFlags]);

  // add listeners on mounting
  useEffect(() => {
    cleanListeners();
    addListeners();
  }, [addListeners, cleanListeners]);

  const loading = isLoading;

  return {
    paymentRequest,
    loading,
    updatePaymentRequest,
  };
};

export {
  useApplyShippingAddress,
  useBasketSetPaymentMethod,
  useOrder,
  useEstimateShipping,
  useWallet,
};
