import { useLocation } from '@reach/router';

const useQueryParamValue = (param: string) => {
  const route = useLocation().search;
  const value = new URLSearchParams(route).get(param);

  return value;
};

const useQueryParamBoolean = (param: string) => {
  const route = useLocation().search;
  const value = new URLSearchParams(route).get(param);

  return value === 'true';
};

const useAllQueryParams = () => {
  const route = useLocation().search;
  const params = new URLSearchParams(route);

  return params.toString();
}

export { useQueryParamValue, useQueryParamBoolean, useAllQueryParams };