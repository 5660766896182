import React, { FC } from 'react';
import { Link } from 'gatsby';
import FILTER_GROUPS_DATA from '../../constants/filter-data';
import IconClose from '../icons/IconClose';
import styles from './filter-item.module.scss';
import { IFilterItem } from '../../interfaces/shop';
import { Badge } from 'vitl-component-library';

interface IFilterItemProps {
  handleClick: () => void;
  filter: IFilterItem;
  isSelected: boolean;
  isDesktopView?: boolean;
}

const FilterItem: FC<IFilterItemProps> = ({ handleClick, filter, isSelected, isDesktopView = false }) => {
  return (
    <div className={styles.filter} onClick={handleClick}>
      <Link
        to={filter.filterUrlPath}
        className={`${isSelected ? styles.selected : ''} ${filter.soon ? styles.disabled : ''}`}
      >
        {filter.label}
        {filter.soon && <span className={styles.soon}>coming soon</span>}
      </Link>
      {isDesktopView && isSelected && (
        <Link to={FILTER_GROUPS_DATA.shop.filterUrlPath}>
          <IconClose />
        </Link>
      )}
      {!isSelected && filter.tag ? (
        <Link to={filter.filterUrlPath}>
          <Badge label={filter.tag} bgColor={'#e8544c'} labelColor={'#ffffff'} isSmall={true} />
        </Link>
      ) : null}
    </div>
  );
};

export default FilterItem;
