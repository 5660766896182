import gql from 'graphql-tag';

export const GET_REGIONS = gql`
  query getRegions {
    regions {
      id
      code
      name
    }
  }
`;

export const STORE_COUNTRIES = gql`
  query storeCountries {
    store_countries {
      id
      code
      name
    }
  }
`;

export const GET_SHIPPING_ADDRESSES = gql`
  query get_Addresses {
    user_deliveryAddresses {
      id
      firstName
      lastName
      street
      city
      region
      postcode
      countryId
    }
  }
`;
