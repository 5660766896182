import { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as Sentry from '@sentry/gatsby';
import { ApolloError } from 'apollo-boost';
//queries
import { BASKET_ADD_ITEM } from '@queries/basket';
//services
import { setCookie } from '@services/cookies';
import { formatGraphQLError } from '@services/format';
import {
  getEventId,
  trackEvent,
  trackFacebook,
  trackPinterest,
  trackReddit,
  trackTiktok,
} from '@services/tracking/tracking';
import { getPriceForAddOrRemoveItem } from '@services/tracking/ga';
//context
import { GlobalContext } from '@store/global-state';
//constants
import { ERROR_MESSAGES } from '@constants/errorMessages';
import { COOKIES, COOKIE_EXPIRES_IN } from '@constants/cookies';
import { GRAPH_QL_ERROR_TYPES } from '@src/constants';
// hooks
import { updateCachedBasket } from '@hooks/useBasket';
// interfaces
import { IBasket } from '@src/types/basket';
import { IBasketAddItemMutationResponse } from '../../../types/mutation-response';

const trackAddToCart = (basketData: any, eventName: string) => {
  const { currency, lastModifiedItem } = basketData;
  const value = getPriceForAddOrRemoveItem(lastModifiedItem);
  const eventId = getEventId();

  trackReddit(eventName, { currency, itemCount: 1, value });
  trackFacebook(
    eventName,
    {
      currency,
      value,
      content_ids: [lastModifiedItem.sku],
      content_type: 'product',
    },
    eventId
  );
  trackTiktok(
    eventName,
    {
      content_type: 'product',
      currency,
      value,
    },
    eventId
  );
  trackPinterest(eventName, { currency, value: String(value) });
  // FE AddToBasket used for reports created by Jura, consider updating reports and deleting this event
  trackEvent(`FE ${eventName}`, {
    currency,
    value,
    items: [lastModifiedItem],
  });
  trackEvent(eventName, { currency, value, items: [lastModifiedItem] }, ['analytics'], eventId);
  trackEvent('conversion', { currency, value, items: [lastModifiedItem] }, ['google_ads']); // add to basket event sent as a conversion to google ads
};
export const useAddToBasket = (
  onCompletedCallback?: (basketData: IBasket) => void,
  onError?: (error: ApolloError) => void,
  trackEventName: string = 'AddToCart'
) => {
  const {
    setErrorModalMessage,
    setShowSpecialRequirementCoupon,
    setSpecialRequirementCoupon,
    setHasUserAddedProductToBasket,
    setShowCrossSellSingle,
    showCrossSellSingle,
  } = useContext(GlobalContext);

  const [addItemToBasket, { loading }] = useMutation<IBasketAddItemMutationResponse>(
    BASKET_ADD_ITEM,
    {
      update: (cache, response) => {
        if (!response.data?.basket_addItem) return;
        updateCachedBasket(response.data.basket_addItem);
        setCookie(
          COOKIES.basketId,
          response.data.basket_addItem.basketId,
          COOKIE_EXPIRES_IN.thirtyDays
        );
      },
      onError: error => {
        const regionRestricted = error.graphQLErrors.find(
          (error: any) => error.errorType === GRAPH_QL_ERROR_TYPES.RegionRestricted
        );
        if (regionRestricted) {
          setShowSpecialRequirementCoupon(true);
          setSpecialRequirementCoupon({
            type: regionRestricted?.errorType,
            message: regionRestricted?.message,
          });
        } else {
          error.message
            ? setErrorModalMessage(formatGraphQLError(error.message))
            : setErrorModalMessage(ERROR_MESSAGES.generic);
        }
        Sentry.captureException(error);
        onError && onError(error);
        showCrossSellSingle && setShowCrossSellSingle(false);
      },
      onCompleted: data => {
        setHasUserAddedProductToBasket(true);
        trackAddToCart(data.basket_addItem, trackEventName);
        onCompletedCallback && onCompletedCallback(data.basket_addItem);
      },
    }
  );

  return {
    addItemToBasket,
    loading,
  };
};
