import { useContext } from 'react';
import { useLazyQuery } from 'react-apollo';
//queries
import { DETECT_LOCALE } from '@queries/guest';
//services
import { getCookie, setCookie } from '@services/cookies';
//context
import { GlobalContext } from '@store/global-state';
//constants
import { COOKIES, COOKIE_EXPIRES_IN } from '@constants/cookies';

export const useDetectLocale = () => {
  const { setCurrencyCode } = useContext(GlobalContext);
  const isCurrencySet = getCookie(COOKIES.currency);
  const [detectLocale] = useLazyQuery(DETECT_LOCALE, {
    fetchPolicy: 'network-only',
    onCompleted: (res: any) => {
      if (!isCurrencySet) {
        // Set currency only if we didnt set it with query params before
        const currency = String(res.guest_locale.currency.toUpperCase());
        setCookie(COOKIES.currency, currency, COOKIE_EXPIRES_IN.thirtyDays);
        setCurrencyCode(currency);
      }
      setCookie(COOKIES.userCountry, String(res.guest_locale.country), COOKIE_EXPIRES_IN.thirtyDays);
    },
    onError: () => {
      setCookie(COOKIES.currency, 'GBP', COOKIE_EXPIRES_IN.thirtyDays);
      setCookie(COOKIES.userCountry, 'GB', COOKIE_EXPIRES_IN.thirtyDays);
    },
  });

  return { detectLocale };
};
