import gql from 'graphql-tag';

const planFragment = gql`
  fragment plan on ProductPlan {
    sku
    parentSku
    productName
    default
    planLabel
    rrpPrice
    rrpPriceLabel
    offerPrice
    offerPriceLabel
    type
    currency
    deliveryFrequency
    purchasable
  }
`;

export const GET_PRODUCT = gql`
  query get_product($slug: ID, $id: ID, $freeTrial: Boolean, $partnership: String) {
    product_getProduct(slug: $slug, id: $id, freeTrial: $freeTrial, partnership: $partnership) {
      id
      benefits
      fromAmount
      grouping
      iconUrl
      imageUrl
      label
      longDescription
      name
      pageDescription
      pageTitle
      shortDescription
      pills {
        id
        description
        iconUrl
        ingredients {
          name
          quantity
          nrv
          type
        }
        nutrients {
          name
          amount
          unit
          nrv
          type
        }
        ingredientsList
        label
        longDescription
        pillFormLabel
        shortDescription
      }
      plans {
        ...plan
      }
      webBlockFourTitle
      webBlockOneDescription
      webBlockOneTitle
      webBlockThreeDescription
      webBlockThreeDirectionsDescription
      webBlockThreeDirectionsTitle
      webBlockThreeImage
      webBlockThreeMaximumEfficacyDescription
      webBlockThreeMaximumEfficacyTitle
      webBlockThreeNoNastiesDescription
      webBlockThreeNoNastiesTitle
      webBlockThreeTitle
      webBlockTwoDescription
      webBlockTwoTitle
    }
  }
  ${planFragment}
`;

export const PRODUCT_PLANS = gql`
  query product_plans($productId: ID, $currencyCacheKey: String) {
    product_plans(productId: $productId, currencyCacheKey: $currencyCacheKey) {
      ...plan
    }
  }
  ${planFragment}
`;

export const CROSS_SELL_BASKET_PRODUCTS_MARKETING = gql`
  query content_crossSellBasket($currencyCacheKey: String) {
    content_crossSellBasket(currencyCacheKey: $currencyCacheKey) {
      basket {
        status
        configurableSku
        name
        imageUrl
        description
        cta
        sku
        price
      }
      popup {
        status
        configurableSku
        name
        imageUrl
        content
        cta
        sku
        price
      }
    }
  }
`;

export const CROSS_SELL_MY_VITL_PRODUCTS = gql`
  query content_crossSellMyVitl {
    content_crossSellMyVitl {
      status
      configurableSku
      name
      tag
      imageUrl
      backgroundColor
      caption
      callouts
      cta
      minPrice
      route
    }
  }
`;
