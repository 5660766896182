import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { TopNavigation } from 'vitl-component-library';
import { IImage } from '@interfaces/image';
import { GlobalContext } from '@store/global-state';
import { useIsPrivateRoute } from '@src/hooks/useIsPrivateRoute';
import useLogoutUser from '@hooks/useLogoutUser';
import { useSearch } from '../search/useSearch';
import { useBasketItemCount } from '@hooks/useBasket';
import { shouldClearBasket } from '@services/basket';
import { useLocation } from '@reach/router';
import { trackClickSearchButton, trackClickSearchedProduct } from '@services/tracking/tracking';

interface IProps {
  logo: IImage;
  siteTitle: string;
}

const TopNavigationComponent = ({ logo, siteTitle }: IProps) => {
  const {
    bannerHeight,
    showStickyNav,
    setShowStickyNav,
    windowIsScrolled,
    setWindowIsScrolled,
    showShopModal,
    isLoggedIn,
    setShowAddCoupon,
    sidebar,
    setSidebar,
    showBasket,
    setShowBasket,
    userKitStatus,
  } = useContext(GlobalContext);

  const isPrivateRoute = useIsPrivateRoute();
  const location = useLocation();

  const { data, loading } = useBasketItemCount();

  const itemCount = data?.basket_itemCount;
  const dataBasket = { itemCount: itemCount ? itemCount : null };

  const navigateTo = (path: string) => {
    if (path === '/account/result/blood') {
      // if You have purchased a blood test
      if (userKitStatus?.purchaseStatus?.hasPurchasedBloodKit) {
        navigate('/account/result/blood');
        return;
      }
      navigate('/product/blood');
      return;
    }
    navigate(path);
  };

  return (
    <TopNavigation
      logo={logo}
      siteTitle={siteTitle}
      rootUrl={process.env.GATSBY_HOST_NAME || 'https://vitl.com'}
      bannerHeight={bannerHeight}
      showStickyNav={showStickyNav}
      setShowStickyNav={setShowStickyNav}
      windowIsScrolled={windowIsScrolled}
      setWindowIsScrolled={setWindowIsScrolled}
      showShopModal={showShopModal}
      isLoggedIn={isLoggedIn}
      sidebar={sidebar}
      showBasket={showBasket}
      setShowBasket={setShowBasket}
      setSidebar={setSidebar}
      isPrivateRoute={isPrivateRoute}
      useLogoutUser={useLogoutUser}
      useSearch={useSearch}
      navigateTo={navigateTo}
      setShowAddCoupon={setShowAddCoupon}
      dataBasket={dataBasket}
      loadingBasket={loading}
      shouldClearBasket={shouldClearBasket}
      location={location?.pathname}
      tracking={{ trackClickSearchedProduct, trackClickSearchButton }}
      hideSearch={false}
    />
  );
};

export default TopNavigationComponent;
