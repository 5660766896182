import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

// context
import { GlobalContext } from '@store/global-state';

// hooks
import { useSearch } from './useSearch';

// styles
import styles from './search.module.scss';
import fadeTransition from '../../styles/fade.module.scss';
import SearchResult from './search-result';

interface IProps {
  isOpen: boolean;
  toggleSearch: (search: boolean) => void;
}

const SearchContainer: React.FC = () => {
  const { filteredProducts, filterProducts, redirectToCbd } = useSearch();
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearchInput = (value: string) => {
    setSearchValue(value);
    filterProducts(value);
  };

  return (
    <>
      <input
        id={'search-input'}
        className={styles.searchInput}
        data-testid={'search-input'}
        placeholder={'Search products'}
        onInput={e => handleSearchInput(e.target.value)}
        onKeyPress={e => (e.key === 'Enter' ? redirectToCbd(e.target.value) : null)}
        value={searchValue}
      ></input>
      <SearchResult searchValue={searchValue} filteredProducts={filteredProducts} />
    </>
  );
};

const Search: React.FC<IProps> = ({ isOpen, toggleSearch }) => {
  const { appHeight } = useContext(GlobalContext);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('body-fixed');
    } else {
      document.body.classList.remove('body-fixed');
    }
  }, [isOpen]);

  return (
    <CSSTransition in={isOpen} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <div className={styles.headerSearch} style={{ height: appHeight }}>
        <button type="button" className={styles.buttonClose} onClick={() => toggleSearch(false)}>
          <img src={'/images/icon_close.svg'} alt="" />
        </button>
        <SearchContainer />
      </div>
    </CSSTransition>
  );
};

export default Search;
