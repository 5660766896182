import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
//hooks
import { useBasket } from './useBasket';
import { GlobalContext } from '@store/global-state';
//utils
import { getCurrency } from '@src/utils/currency';
//queries
import { CROSS_SELL_BASKET_PRODUCTS_MARKETING } from '@queries/product';
//interfaces
import { ICrossSellBasketProductsQueryResponse } from '@src/types/query-response';
import { IBasketItem } from '../types/basket';
import { IBasketCrossSellProduct } from '@src/types/product';

const useCrossSellProducts = () => {
  const { data: basketData } = useBasket('cache-first');
  const { hasUserAddedProductToBasket } = useContext(GlobalContext);

  const { data: crossSellBasketProductsData, loading: loadingCrossSellBasketProducts } = useQuery<
    ICrossSellBasketProductsQueryResponse
  >(CROSS_SELL_BASKET_PRODUCTS_MARKETING, {
    fetchPolicy: 'network-only',
    variables: {
      currencyCacheKey: getCurrency(),
    },
  });

  const crossSellBasketProducts = crossSellBasketProductsData?.content_crossSellBasket;

  const filterPublishedProducts = (products: any) => {
    if (!products || !products.length) return [];
    return products.filter((item: any) => item.status === 'published');
  };

  const usePopupCrossSellProducts = () => {
    const [crossSellBasketPopupProduct, setCrossSellBasketPopupProduct] = useState();

    useEffect(() => {
      const popupProducts = filterPublishedProducts(crossSellBasketProducts?.popup);

      if (!popupProducts) return;
      const popupProduct = popupProducts[0];

      const isCrossSellProductInTheBasket = (products: IBasketItem[] | undefined) => {
        return products?.some(item => {
          return item?.sku?.startsWith(popupProduct.sku);
        });
      };

      if (!hasUserAddedProductToBasket || !popupProduct) return;

      const oneItemInCart = basketData && basketData.itemCount === 1;
      if (!oneItemInCart) return;
      if (isCrossSellProductInTheBasket(basketData?.items)) return;

      setCrossSellBasketPopupProduct(popupProduct);
    }, [basketData, crossSellBasketProducts]);

    return crossSellBasketPopupProduct;
  };

  const useBasketCrossSellProducts = () => {
    const [basketProducts, setBasketProducts] = useState<IBasketCrossSellProduct[]>([]);

    useEffect(() => {
      const excludeCartProducts = (products: IBasketCrossSellProduct[]) => {
        const basketProductSkus = basketData?.items?.map(item => item.sku);

        return products?.filter(
          (item: IBasketCrossSellProduct) => !basketProductSkus?.includes(item.sku)
        );
      };

      const calculateBasketProducts = () => {
        const filteredPublishedProducts = filterPublishedProducts(crossSellBasketProducts?.basket);
        const excludedCartProducts = excludeCartProducts(filteredPublishedProducts);
        setBasketProducts(excludedCartProducts?.slice(0, 3));
      };
      calculateBasketProducts();
    }, [basketData, crossSellBasketProducts]);

    return {
      basketProducts,
      loading: loadingCrossSellBasketProducts,
    };
  };

  return {
    usePopupCrossSellProducts,
    useBasketCrossSellProducts,
  };
};

export default useCrossSellProducts;
