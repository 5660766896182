import React, { useState } from 'react';

import StepTwo from './step-two';
import StepOne from './step-one';
import { IBasket, IBasketItem } from '../../../../types/basket';
import { getCurrency } from '@src/utils/currency';

const ChooseYourPlan = () => {
  const [itemAddedToBasket, seItemAddedToBasket] = useState<null | IBasketItem>(null);
  const [basketData, setBasketData] = useState<null | IBasket>(null);

  const onComplete = (data: IBasket) => {
    setBasketData(data);
    seItemAddedToBasket(data.lastModifiedItem);
  };

  if (basketData && itemAddedToBasket)
    return <StepTwo item={itemAddedToBasket} currency={basketData.currency || getCurrency()} />;

  return <StepOne onComplete={onComplete} />;
};

export default ChooseYourPlan;
