import { useMutation } from '@apollo/react-hooks';
import { BASKET_UPSELL_ITEM } from '@queries/basket';
import { IBasketUpsellItem } from '../../../types/mutation-response';
import * as Sentry from '@sentry/gatsby';
import { useContext } from 'react';
import { GlobalContext } from '@store/global-state';
import { formatGraphQLError } from '@services/format';
import { ERROR_MESSAGES } from '@src/constants';
import { updateCachedBasket } from '@hooks/useBasket';

export const useBasketUpsellItem = (onCompletedCallback: () => void) => {
  const { setErrorModalMessage } = useContext(GlobalContext);

  const [addBasketUpsellItem, { loading }] = useMutation<IBasketUpsellItem>(BASKET_UPSELL_ITEM, {
    update: (cache, response) => {
      if (!response.data) return;
      updateCachedBasket(response.data.basket_upsellItem);
    },
    onError: error => {
      error.message
        ? setErrorModalMessage(formatGraphQLError(error.message))
        : setErrorModalMessage(ERROR_MESSAGES.generic);
      Sentry.captureException(error);
    },
    onCompleted: () => {
      onCompletedCallback();
    },
  });

  return {
    addBasketUpsellItem,
    loading,
  };
};
