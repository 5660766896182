import React from 'react';
import LayoutContent from './layout-content';
import VWOSmartCode from '@services/tracking/VWOSmartCode';

import 'vitl-component-library/dist/index.css';

interface IProps {
  showHeader?: boolean;
  showFooter?: boolean;
  children: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ showFooter, showHeader, children }) => {
  return (
    <LayoutContent showFooter={showFooter} showHeader={showHeader}>
      <VWOSmartCode />
      {children}
    </LayoutContent>
  );
};

Layout.defaultProps = {
  showHeader: true,
  showFooter: true,
};

export default Layout;
