import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { GlobalContext } from '@store/global-state';
import { SIDEBAR_VIEWS } from '@components/sidebar/constants';

import styles from './sidebar-banner.module.scss';

const SidebarBanner = () => {
  const { setSidebar } = useContext(GlobalContext);
  return (
    <div className={styles.sideBanner}>
      <Link to="/consultation" className={styles.button} onClick={() => setSidebar(SIDEBAR_VIEWS.empty)}>
        <img src="/images/banners/hamburger-menu-shop.jpg" alt="banner image" />
      </Link>
    </div>
  );
};

export default SidebarBanner;
