import React, { FC } from 'react';
//interfaces
import { IBasketCrossSellProduct } from '@src/types/product';
//components
import Image from '@components/image/image';
import { Button } from 'vitl-component-library';
//hooks
//styles
import styles from './product-carousel-item.module.scss';
import useGetProductPlansData from '@features/shop/hooks/useGetProductPlansData';

interface IProductCarousel {
  product: IBasketCrossSellProduct;
  onClickProduct?: (sku: string) => void;
}

const ProductCarouselItem: FC<IProductCarousel> = ({ product, onClickProduct }) => {
  const { sku, name, description, cta, imageUrl, configurableSku } = product;

  const { price, offerPrice } = useGetProductPlansData(configurableSku, undefined, 'oneOff');
  return (
    <article className={styles.wrapper}>
      <div className={styles.image}>
        <Image
          src={`${process.env.GATSBY_DIRECTUS_MARKETING_MEDIA_URL}/${imageUrl}`}
          width={100}
          height={100}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.description}>
          <p className={styles.title}>{name}</p>
          <p className={styles.subtitle}>{description}</p>
        </div>
        <div className={styles.priceContainer}>
          <span className={styles.price}>
            {offerPrice ? <s>{price} </s> : null}
            {offerPrice || price}
          </span>
          <Button
            className={styles.button}
            title={cta || ''}
            primary={false}
            xSmall
            success
            onClick={() => onClickProduct && onClickProduct(sku)}
          />
        </div>
      </div>
    </article>
  );
};

export default ProductCarouselItem;
