import React from 'react';

import SubmitButton from '@components/submit-button';
// styles
import styles from './modal-footer.module.scss';

interface IProps {
  type?: string;
  closeLabel: string;
  saveLabel: string;
  isSaveDisabled?: boolean;
  handleClose: () => void;
  handleSave: () => void;
  loading?: boolean;
}

const ModalFooter: React.FC<IProps> = ({
  type = '',
  handleClose,
  handleSave,
  closeLabel,
  saveLabel,
  isSaveDisabled = false,
  loading = false,
}) => {
  return (
    <div className={styles['content' + type]}>
      <button className={styles.buttonGhost} type="button" onClick={handleClose}>
        {closeLabel}
      </button>
      {saveLabel !== '' && (
        <SubmitButton
          className={styles.buttonPrimary}
          disabled={isSaveDisabled || loading}
          loading={loading}
          onClick={handleSave}
          buttonLabel={saveLabel}
        >
          {saveLabel}
        </SubmitButton>
      )}
    </div>
  );
};

export default ModalFooter;
