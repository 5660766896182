import { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';

export const useSelectedFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    setSelectedFilter(pathname);
  }, [pathname]);

  return selectedFilter;
};
