import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { FILTER_ANIMATION } from './animation';
import { isSSR } from '@services/global';
import { GlobalContext } from '@store/global-state';
import { SIDEBAR_VIEWS } from '@components/sidebar/constants';
import FilterItem from '../filter-item/FilterItem';
import IconDown from '../icons/IconDown';
import IconUp from '../icons/IconUp';
import styles from './filter-group.module.scss';
import { IFilterGroup } from '../../interfaces/shop';
import { useSelectedFilter } from '../../hooks/useSelectedFilter';

interface IProps {
  filterGroup: IFilterGroup;
  isDesktopView?: boolean;
  className?: string;
}

const FilterGroup = ({ filterGroup, isDesktopView, className }: IProps) => {
  const selectedFilter = useSelectedFilter();
  const { setSidebar } = useContext(GlobalContext);
  const closeSidebar = () => setSidebar(SIDEBAR_VIEWS.empty);

  const collapsedFilter = !isSSR && localStorage.getItem(`desktopFilterState-${filterGroup.name}`);

  const [isCollapsed, setIsCollapsed] = useState(
    isDesktopView && !!collapsedFilter && JSON.parse(collapsedFilter) === true
  );

  const onCollapseFilter = (state: boolean) => {
    setIsCollapsed(state);
    localStorage.setItem(`desktopFilterState-${filterGroup.name}`, `${state}`);
  };

  return (
    <div className={`${styles.filterGroup} ${className}`} data-testid="filter-group">
      <div
        className={styles.header}
        onClick={isCollapsed ? () => onCollapseFilter(false) : () => onCollapseFilter(true)}
      >
        <span>{filterGroup.name}</span>
        {isDesktopView && <span>{isCollapsed ? <IconDown /> : <IconUp />}</span>}
      </div>
      <motion.div
        className={styles.filters}
        initial={isCollapsed ? 'closed' : 'open'}
        animate={isCollapsed ? 'closed' : 'open'}
        variants={FILTER_ANIMATION}
      >
        {filterGroup.filters.map(filter => (
          <FilterItem
            key={filter.label}
            filter={filter}
            handleClick={closeSidebar}
            isSelected={selectedFilter === filter.filterUrlPath}
            isDesktopView={isDesktopView}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default FilterGroup;
