import React from 'react';
import { navigate } from 'gatsby';

import { trackClickSearchedProduct } from '@services/tracking/tracking';

import styles from './search.module.scss';

interface IProps {
  searchValue: string;
  filteredProducts: any[];
  callback?: () => void;
}

const SearchResult = ({ searchValue, filteredProducts, callback }: IProps) => {
  const handleClickProduct = (link: string) => {
    trackClickSearchedProduct(searchValue, link);
    navigate(link);
    callback && callback();
  };

  if (!searchValue) {
    return null;
  }

  return (
    <div className={styles.searchDropdown} data-testid={'search-dropdown'}>
      <span className={styles.searchDesc}>{filteredProducts.length} products found</span>
      <ul className={styles.searchList}>
        {filteredProducts.map((product: any) => (
          <li className={styles.searchItem} key={product.name}>
            <a onClick={() => handleClickProduct(product.link)}>
              {product.image ? <img src={product.image}></img> : null}
              <span>{product.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchResult;
