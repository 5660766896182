import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { GlobalContext } from '@store/global-state';
import FilterGroup from '../filter-group/filter-group';
import FILTER_GROUPS_DATA, { MOBILE_FILTER_GROUPS } from '@features/shop/constants/filter-data';

import styles from './mobile-filters-sidebar.module.scss';

const MobileFiltersSidebar = () => {
  const { filterName } = useContext(GlobalContext);

  return (
    <div>
      {filterName === MOBILE_FILTER_GROUPS.categories && (
        <>
          <FilterGroup filterGroup={FILTER_GROUPS_DATA.categories} />
          <div className={styles.divider} />
          <FilterGroup filterGroup={FILTER_GROUPS_DATA.byType} />
        </>
      )}
      {filterName === MOBILE_FILTER_GROUPS.benefits && <FilterGroup filterGroup={FILTER_GROUPS_DATA.benefits} />}
      <div className={styles.bottom}>
        <p>Not sure what you need?</p>
        <Link to="/consultation" className={styles.buttonWrap}>
          <span className={styles.button}>Take Health Quiz</span>
        </Link>
      </div>
    </div>
  );
};

export default MobileFiltersSidebar;
