import React, { FC } from 'react';

import { useShopText } from '@features/shop/hooks/useShopText';

import styles from './add-to-basket-new.module.scss';
import { IProductPlan } from '../../../../types/product';

interface ISubscriptionBenefitsProps {
  plan: IProductPlan;
}

const Image = () => <img src="/images/icon-compare-check.svg" alt="check icon" />;

const SubscriptionBenefits: FC<ISubscriptionBenefitsProps> = ({ plan }) => {
  const shopText = useShopText();
  return (
    <div className={styles.badges}>
      <p>
        <Image />{' '}
        {plan.deliveryFrequency
          ? `Delivered ${plan.deliveryFrequency}`
          : shopText.delivered_every_30_days}
      </p>
      <p>
        <Image />
        {shopText.pause_or_cancel}
      </p>
      <p>
        <Image />
        {shopText.free_delivery}
      </p>
    </div>
  );
};

export default SubscriptionBenefits;
