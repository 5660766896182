import React, { useContext } from 'react';

import { useShopText } from '@features/shop/hooks/useShopText';
import { formatPrice } from '@services/format';
import { GlobalContext } from '@store/global-state';
//types
import { IBasketItem } from '../../../../types/basket';
//styles
import styles from './step-two.module.scss';

interface IProps {
  item: IBasketItem;
  currency: string;
}

const StepTwo = ({ item, currency }: IProps) => {
  const shopText = useShopText();
  const { setShowBasket, setShowShopModal, setCurrentProductAndPlanData } = useContext(
    GlobalContext
  );

  const [name, subscription] = item.name!.split('-');

  const keepShopping = () => {
    setShowShopModal(false);
  };

  const viewBasket = () => {
    setShowShopModal(false);
    setCurrentProductAndPlanData(null);
    setShowBasket(true);
  };

  return (
    <div className={styles.choosePlanStepTwo}>
      <h4>
        <img src="/images/icon-compare-check-circle.svg" alt="" /> {shopText.added_to_your_basket}
      </h4>
      <div className={styles.wrap}>
        <div className={styles.leftSide}>
          <img src={item.image} alt={item.name} />
          <div className={styles.itemWrap}>
            <p>{name}</p>
            <span>
              {item.qty} x {formatPrice(item.itemPrice, currency)} {subscription}
            </span>
          </div>
        </div>
        <div className={styles.rightSide}>
          <button className={styles.button} type="button" onClick={keepShopping}>
            {shopText.keep_shopping}
          </button>
          <button
            data-testid="view-basket"
            className={styles.buttonPrimary}
            type="button"
            onClick={viewBasket}
          >
            {shopText.view_basket}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
