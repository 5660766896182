import React, { FC, useEffect, useState } from 'react';
import { client } from '@src/apollo/client';
//components
import Loader from '@components/loader/loader';
import ProductsCarousel from './ProductsCarousel/ProductsCarousel';
//features
import { useAddToBasket } from '@features/shop/hooks/useAddToBasket';
//hooks
import { updateCachedBasket, useCachedBasket } from '@hooks/useBasket';
//services
import { getCookie } from '@services/cookies';
import { getPriceForAddOrRemoveItem } from '@services/tracking/ga';
import {
  getEventId,
  trackEvent,
  trackFacebook,
  trackPinterest,
  trackReddit,
  trackTiktok,
} from '@services/tracking/tracking';
//constants
import { COOKIES } from '@constants/cookies';
import { IBasketCrossSellProduct } from '@src/types/product';

//styles
import styles from './basket-recommended-products.module.scss';

interface useCrossSellBasketProductsBasketReturn {
  basketProducts: IBasketCrossSellProduct[];
  loading: boolean;
}

interface IBasketRecommendedProducts {
  getCrossSellBasketProductsBasket: () => useCrossSellBasketProductsBasketReturn;
  title?: string;
  bgColor?: string;
  setLoadingBasket: (loading: boolean) => void;
}

const BasketRecommendedProducts: FC<IBasketRecommendedProducts> = ({
  getCrossSellBasketProductsBasket,
  title = 'Discover other bestsellers',
  bgColor = '#F8F4EB',
  setLoadingBasket,
}) => {
  const { addItemToBasket, loading: loadingAddToBasket } = useAddToBasket();
  const {
    basketProducts,
    loading: loadingCrossSellBasketProducts,
  } = getCrossSellBasketProductsBasket();

  const [activeSlide, setActiveSlide] = useState(0);

  const onClickProduct = async (sku: string) => {
    const result = await addItemToBasket({
      variables: {
        basketId: getCookie(COOKIES.basketId),
        sku: sku,
        quantity: 1,
      },
    });
    if (!result) return;
    const data = result.data;
    if (!data) return;

    const basketData = useCachedBasket(client);
    basketData.basket_getBasket = data.basket_addItem;
    updateCachedBasket(basketData.basket_getBasket);
    const { currency, lastModifiedItem } = data.basket_addItem;
    const value = getPriceForAddOrRemoveItem(lastModifiedItem);

    const eventName = `basket_crossell_add_${activeSlide}_${lastModifiedItem.sku}`;
    const eventId = getEventId();

    trackReddit(eventName, { currency, itemCount: 1, value });
    trackFacebook(eventName, { currency, value }, eventId);
    trackTiktok(eventName, {
      content_type: 'product',
      currency,
      value,
    }, eventId);
    trackPinterest(eventName, { currency, value: value });
    // FE AddToBasket used for reports created by Jura, consider updating reports and deleting this event
    trackEvent(
      eventName,
      {
        currency,
        value,
        items: [lastModifiedItem],
      },
      undefined,
      eventId
    );
    trackEvent(eventName, {
      currency,
      value,
      items: [lastModifiedItem],
    });
  };

  useEffect(() => {
    setLoadingBasket(loadingAddToBasket);
  }, [loadingAddToBasket]);

  return (
    <section className={styles.wrapper} style={{ backgroundColor: bgColor }}>
      <p className={styles.heading}>{title}</p>
      {loadingCrossSellBasketProducts ? (
        <Loader hasContainer={true} />
      ) : (
        <ProductsCarousel {...{ products: basketProducts, onClickProduct, setActiveSlide }} />
      )}
    </section>
  );
};

export default BasketRecommendedProducts;
