import gql from 'graphql-tag';

export const CONTENT_BANNER = gql`
  query content_banner($path: String!) {
    content_banner(path: $path) {
      label
      buttonLabel
      buttonUrl
      backgroundColor
      textColor
      isDismissible
      autoHide
      endDate
    }
  }
`;
