import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'gatsby';
// context
import { GlobalContext } from '@store/global-state';
import { GRAPH_QL_ERROR_TYPES } from '../../constants';
// components
import Modal from '../modal';
import ModalHeader from '../modal/modal-header';
import ModalBody from '../modal/modal-body';
import ModalFooter from '../modal/modal-footer';
// styles
import styles from './modal-special-requirement-coupon.module.scss';
import fadeTransition from '../../styles/fade.module.scss';

const ModalSpecialRequirementCoupon: React.FC = () => {
  const { showSpecialRequirementCoupon, setShowSpecialRequirementCoupon, specialRequirementCoupon } = useContext(
    GlobalContext
  );

  const couponRequiresAccount = specialRequirementCoupon.type === GRAPH_QL_ERROR_TYPES.CouponRequiresAccount;
  const couponRequiresHealthProfile =
    specialRequirementCoupon.type === GRAPH_QL_ERROR_TYPES.CouponRequiresHealthProfile;
  const couponValidFirstOrderOnly = specialRequirementCoupon.type === GRAPH_QL_ERROR_TYPES.CouponValidFirstOrderOnly;
  const regionRestricted = specialRequirementCoupon.type === GRAPH_QL_ERROR_TYPES.RegionRestricted;

  return (
    <CSSTransition in={showSpecialRequirementCoupon} timeout={300} classNames={{ ...fadeTransition }} unmountOnExit>
      <Modal
        handleClose={() => {
          setShowSpecialRequirementCoupon(false);
        }}
      >
        {regionRestricted && (
          <>
            <ModalHeader>
              <h2 className={styles.title}>Unavailable in your region</h2>
              <hr />
            </ModalHeader>
            <ModalBody>
              <div className={styles.alignCenter}>
                <p>{specialRequirementCoupon.message}</p>
                <button
                  type="button"
                  className={styles.buttonPrimary}
                  onClick={() => setShowSpecialRequirementCoupon(false)}
                >
                  Ok
                </button>
              </div>
            </ModalBody>
          </>
        )}

        {couponValidFirstOrderOnly && (
          <>
            <ModalHeader>
              <h2 className={styles.title}>Coupon invalid</h2>
              <hr />
            </ModalHeader>
            <ModalBody>
              <div className={styles.alignCenter}>
                <p>{specialRequirementCoupon.message}</p>
                <button
                  type="button"
                  className={styles.buttonPrimary}
                  onClick={() => setShowSpecialRequirementCoupon(false)}
                >
                  Ok
                </button>
              </div>
            </ModalBody>
          </>
        )}

        {couponRequiresAccount && (
          <>
            <ModalHeader>
              <h2 className={styles.title}>Please login</h2>
              <hr />
            </ModalHeader>
            <ModalBody>
              <div className={styles.alignCenter}>
                <p>{specialRequirementCoupon.message}</p>
                <Link to={'/account/signin'} className={styles.buttonPrimary}>
                  Take me to login
                </Link>
              </div>
            </ModalBody>
          </>
        )}
        {couponRequiresHealthProfile && (
          <>
            <ModalHeader>
              <h2 className={styles.title}>Oops!</h2>
              <hr />
            </ModalHeader>
            <ModalBody>
              <div className={styles.alignCenter}>
                <p>{specialRequirementCoupon.message}</p>
              </div>
            </ModalBody>
            <ModalFooter
              handleClose={() => setShowSpecialRequirementCoupon(false)}
              handleSave={() => setShowSpecialRequirementCoupon(false)}
              saveLabel="Take Health Quiz"
              closeLabel="Close"
            />
          </>
        )}
      </Modal>
    </CSSTransition>
  );
};

export default ModalSpecialRequirementCoupon;
