import gql from 'graphql-tag';

export const DETECT_LOCALE = gql`
  query guest_locale {
    guest_locale {
      currency
      country
    }
  }
`;

export const CREATE_GUEST = gql`
  mutation guest_create {
    guest_create
  }
`;
