import gql from 'graphql-tag';
import { paymentMethodFragment } from '@queries/fragments';
export const PAYMENT_METHODS = gql`
  query user_paymentMethods {
    user_paymentMethods {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation user_updatePaymentMethod($id: ID!, $postcode: String, $setAsDefault: Boolean) {
    user_updatePaymentMethod(id: $id, postcode: $postcode, setAsDefault: $setAsDefault) {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation user_removePaymentMethod($id: ID!) {
    user_removePaymentMethod(id: $id) {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`;

export const CREATE_PAYMENT_SETUP_INTENT = gql`
  mutation user_createPaymentSetupIntent($token: String!, $setAsDefault: Boolean) {
    user_createPaymentSetupIntent(token: $token, setAsDefault: $setAsDefault) {
      clientSecret
    }
  }
`;

export const SUBSCRIPTION_UPDATE_PAYMENT_METHOD = gql`
  mutation subscription_updatePaymentMethod($paymentMethodId: ID!, $subscriptionId: ID) {
    subscription_updatePaymentMethod(
      paymentMethodId: $paymentMethodId
      subscriptionId: $subscriptionId
    )
  }
`;
