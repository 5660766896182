import React, { FC } from 'react';
import { formatPrice } from '@services/format';
import { getCountryNameById } from '@services/global';
import styles from '../basket.module.scss';

interface IBasketDeliveryDetailsProps {
  basket: any;
  currency: string;
  countries?: any[];
}

const BasketDeliveryDetails: FC<IBasketDeliveryDetailsProps> = ({
  basket,
  currency,
  countries,
}) => {
  return (
    <div className={styles.rowBasketDetails}>
      <p className={styles.deliveryLabel}>
        {countries && basket.deliveryAddress.countryId && (
          <span className={styles.estimatedLabel}>{`
              Delivered to
            ${getCountryNameById(countries, basket.deliveryAddress.countryId)}`}</span>
        )}
      </p>
      <p className={styles.totalLabelFormat}>
        {basket.shipping === 0 ? (
          <span className={styles.textFree}>FREE</span>
        ) : (
          formatPrice(basket.shipping, currency)
        )}

        {/*{basket?.details?.deliveryAddress === null && (*/}
        {/*  <span*/}
        {/*    className={`${styles.estimatedLabel} ${styles.textUnderline}`}*/}
        {/*    onClick={() => setIsEstimatedDeliveryModalVisible(true)}*/}
        {/*  >*/}
        {/*    Estimated delivery*/}
        {/*  </span>*/}
        {/*)}*/}
      </p>
    </div>
  );
};

export default BasketDeliveryDetails;
